import React, { useState } from 'react'
import PropTypes from 'prop-types'
import logo from '../../assets/RolePlayMagic_white.png'
import { SettingFilled, SoundFilled } from '@ant-design/icons'
import { Button, Card, Row, Space, Col, Dropdown, Modal, Badge } from 'antd'
import './dropdown.css'
import { useDispatch, useSelector } from 'react-redux'
import { setBackgroundMusic } from '../../redux/actions/backgroundMusic'
import GameListModal from '../../view/GameListModal'
import LoginSignUpModal from '../LoginSignupModal'
import { getGameAndAccessId } from '../../utils/helpers'
import { ReadOutlined, MessageFilled, VideoCameraOutlined, BellFilled, InfoOutlined } from '@ant-design/icons'

const HeaderButtons = ({
	ui_images = {},
	setIsDmModelVisible,
	setIsLibraryModelVisible,
	logout,
	dataGame,
	releaseCharacter,
	setIsNewsModelVisible,
	setIsMessengerModelVisible
}) => {
	const { gameId } = getGameAndAccessId()
	const { muteStatus } = useSelector((state) => state.backgroundMusic)
	const dispatch = useDispatch()
	const [gameListModal, setGameListModal] = useState(null)
	const [visible, setVisible] = useState(false);

	const handleVisibleChange = (flag) => {
		setVisible(flag);
	  };

	const backgroundMusic = () => {
		dispatch(setBackgroundMusic(!muteStatus))
	}


	const main_chat_alerts = dataGame?.chat_alerts || {}

	let unseen_total = 0
	Object.values(main_chat_alerts).forEach((row) => {
		row.forEach((item) => {
			if (!item.seen) {
				unseen_total += 1
			}
		})
	})

	return (
		<>
			<div className='action-buttons d-flex justify-end align-center'>
				<div className='flex-grow-1 mr-2'>
					<div className='d-flex justify-end mb-3'>
						{dataGame && (
							<>
								<button
									style={{ backgroundImage: `url(${ui_images.button_large})` }}
									className='custom-button-settings large-button-settings white'
									onClick={() => setIsLibraryModelVisible(true)}
								>
									Library
								</button>

								{dataGame.player.playerNUM === 99 && (
									<button
										style={{
											backgroundImage: `url(${ui_images.button_small})`,
										}}
										className='custom-button-settings small-button-settings white'
										onClick={() => setIsDmModelVisible(true)}
									>
										DM
									</button>
								)}

								{!muteStatus ? (
									<button
										style={{
											backgroundImage: `url(${ui_images.button_small})`,
										}}
										className='custom-button-settings small-button-settings white'
										onClick={() => {
											backgroundMusic()
										}}
									>
										<span className='volumeBtn material-icons'>volume_up</span>
									</button>
								) : (
									<button
										style={{
											backgroundImage: `url(${ui_images.button_small})`,
										}}
										onClick={() => {
											backgroundMusic()
										}}
										className='custom-button-settings small-button-settings white'
									>
										<span className='volumeBtn material-icons'>volume_off</span>
									</button>
								)}
								<div style={{ position: 'relative' }} onClick={() => setIsMessengerModelVisible(true)}>
									{!!unseen_total ? <BellFilled className='total-notification-bell-icon' /> : null}
									<button
										style={{ backgroundImage: `url(${ui_images.button_small})` }}
										className='custom-button-settings small-button-settings white'
										id='messenger-open-button'
									>
										<MessageFilled />
									</button>
								</div>
								<div style={{ position: 'relative' }} onClick={() => setIsNewsModelVisible(true)}>
									{dataGame?.news_alert ? <BellFilled className='total-notification-bell-icon' /> : null}
									<button
										style={{ backgroundImage: `url(${ui_images.button_small})` }}
										className='custom-button-settings small-button-settings white'
									>
										<ReadOutlined />
									</button>
								</div>

								<Dropdown
									trigger={['click']}
									open={visible}
									onOpenChange={handleVisibleChange}
									overlay={
										<div
											style={{ background: 'white' }}
											className={`drop-down-container ${dataGame.player.playerNUM !== 99 ? 'no-dm' : ''}`}
										>
											<div
												className='menu-items-container'
												onClick={() => {
													setVisible(false);
													setGameListModal({ session_id: dataGame?.player?.email })
												}}
											>
												<li className='menu-items'>Your Games</li>
											</div>
											<div
												className='menu-items-container'
												onClick={() => window.open('https://account.roleplaymagic.com', '_self')}
											>
												<li className='menu-items'>Your Account</li>
											</div>
											<div className='menu-items-container' onClick={logout}>
												<li className='menu-items'>Help Center</li>
											</div>
											{dataGame.player.playerNUM != 99 && (
												<div className='menu-items-container'>
													<li
														onClick={() =>
															Modal.confirm({
																cancelButtonProps: { display: 'block' },
																okText: 'Yes',
																cancelText: 'No',
																title: `Please Confirm`,
																content: `Are you sure you want to release your character? This will exit you from the game!`,
																onOk: releaseCharacter,
															})
														}
														className='menu-items'
													>
														Release Character
													</li>
												</div>
											)}
											<div className='menu-items-container'>
												<li onClick={logout} className='menu-items last'>
													Log Off
												</li>
											</div>
										</div>
									}
									placement='bottomLeft'
								>
									<button
										style={{
											backgroundImage: `url(${ui_images.button_small})`,
										}}
										className='custom-button-settings small-button-settings white'
									>
										<SettingFilled />
									</button>
								</Dropdown>
							</>
						)}
					</div>
					<div className='advert-Box'>Advert</div>
				</div>
				<div
					className='background-settings d-flex justify-center align-center'
					style={{
						backgroundImage: `url(${ui_images.logo_wrapper})`,
						height: '170px',
						width: '203px',
					}}
				>
					<img alt='l' src={ui_images.logo} width={90} />
				</div>
			</div>

			<LoginSignUpModal
				className='ant-modal-width-mid'
				handleCancel={() => setGameListModal(null)}
				isModalVisible={!!gameListModal}
				title={null}
				// wrapClassName='no-modal-close-btn'
				maskClosable={false}
				footer={null}
				bodyStyle={{ height: '550px', overflowY: 'scroll', paddingBottom: 24 }}
			>
				<GameListModal currentGameId={gameId} reusing={true} loggedInData={gameListModal} logOff={() => logout()} />
			</LoginSignUpModal>
		</>
	)
}

HeaderButtons.propTypes = {
	ui_images: PropTypes.object,
	setIsDmModelVisible: PropTypes.func,
	setIsLibraryModelVisible: PropTypes.func,
	logout: PropTypes.func,
	dataGame: PropTypes.object,
	releaseCharacter: PropTypes.func,
	setIsNewsModelVisible: PropTypes.func,
	setIsMessengerModelVisible: PropTypes.func
}
export default HeaderButtons
