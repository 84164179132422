import { Col, Row, Image, Input, Spin, Form, Button, message, Tooltip } from 'antd'
import React from 'react'
import { useEffect, useState } from 'react'
import { callLoadGame, callToApi } from '../../utils'
import { useDispatch } from 'react-redux'
import { setGameDataAction } from './../../redux/actions/gameData/index'
import Dragger from 'antd/lib/upload/Dragger'
import { ArrowUpOutlined, CheckOutlined, CloseOutlined, EditOutlined, FolderOpenFilled } from '@ant-design/icons'

export const getRestPlaceholder = (i) => <Col key={i} span={24} />

const ChararcterStatsTab = ({ character_sheet, playerNum, isDm }) => {
	const [characterLoading, setCharacterLoading] = useState(false)
	const [characterError, setCharacterError] = useState('')
	const [loadedCharacter, setLoadedCharacter] = useState(null)
	const [nameforHighlight, setNameforHighlight] = useState('')
	const [nameforError, setNameforError] = useState('')
	const [nameforLoading, setNameforLoading] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [imageUploadTest, setImageUploadTest] = useState('')
	const dispatch = useDispatch()

	const { characteristics, stats, attributes, bonuses, text_fields, large_text_fields } = character_sheet
	const chara = Object.entries(characteristics)
	const statsArr = Object.entries(stats)
	const textFlieds = Object.entries(text_fields)
	const attributesArr = Object.entries(attributes)
	const bonusesArr = Object.entries(bonuses)
	const largeTextFields = Object.entries(large_text_fields)

	const loadCharacter = async () => {
		setCharacterLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'load_character',
			player_id: playerId,
			playerNUM: playerNum,
		}
		try {
			const { data } = await callToApi({ body })
			setLoadedCharacter({ ...data })
			setCharacterLoading(false)
			setCharacterError('')
		} catch (err) {
			setCharacterError('something went wrong')
			setCharacterLoading(false)
			console.log(err.response)
		}
	}

	const makeHighlightSuccess = () => {
		setTimeout(() => {
			setNameforHighlight('')
		}, 2000)
	}

	const makeHighlightError = () => {
		setTimeout(() => {
			setNameforError('')
		}, 2000)
	}

	const updateCharacter = async (f, v) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'update_character',
			player_id: playerId,
			playerNUM: playerNum,
			field: f,
			value: v,
		}
		try {
			const { data } = await callToApi({ body })

			setLoadedCharacter(data)
			setNameforLoading('')
			setNameforHighlight(f)
			makeHighlightSuccess()
			setImageLoading(false)
			loadGame()
		} catch (err) {
			setImageLoading(false)
			setNameforLoading('')
			setNameforError(f)
			makeHighlightError()

			console.log(err)
		}
	}

	const releaseCharacter = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'release_character',
			player_id: playerId,
			playerNUM: playerNum,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('Character Released')
			loadGame()
		} catch (err) {
			console.log(err)
			message.error('Action Failed')
		}
	}

	const handleBlur = (e) => {
		setNameforLoading(e.target.name)
		updateCharacter(e.target.name, e.target.value)
	}

	useEffect(() => {
		loadCharacter()
	}, [])

	const uploadImage = (file) => {
		var reader = new FileReader()
		if (file) {
			reader.readAsDataURL(file)
			reader.onload = () => {
				var Base64 = reader.result
				setImageLoading(true)
				updateCharacter('imageISfile', Base64)
			}
			reader.onerror = (error) => {
				console.log('error: ', error)
			}
		}
	}

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			setImageLoading(false)
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	if (characterLoading) {
		return (
			<div className='text-center my-3'>
				<Spin className='mr-2' /> loading character ...
			</div>
		)
	}

	if (characterError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => loadCharacter()}>Try again</Button>
			</div>
		)
	}

	if (loadedCharacter) {
		return (
			<div className='scrollable-one '>
				<Row className='mb-4'>
					<Col span={8} lg={8} xs={24}>
						<div className='image-upload-custom mb-2 d-flex'>
							<div>
								<Tooltip title={'Click or Drag and drop image here'} className='draggerTooltip'>
									<Dragger
										className={loadedCharacter.imageISfile ? 'draggerrCharacterStats' : 'draggerrCharacterView'}
										multiple='false'
										maxCount={1}
										onRemove={() => message.info('not working right now')}
										beforeUpload={(file) => {
											uploadImage(file)
											return false
										}}
									>
										{loadedCharacter.imageISfile ? (
											<>
												<div
													className='background-settings mx-2'
													style={{
														backgroundImage: `url(${loadedCharacter.imageISfile})`,
														minHeight: '150px',
														backgroundSize: 'contain',
													}}
												></div>
												<EditOutlined className='edit-dp' />
											</>
										) : (
											<>
												<p className='ant-upload-drag-icon'>
													<FolderOpenFilled />
												</p>
												<p className='dragger-text my-25'>
													Drag your documents, photos, or videos here to start uploading.
												</p>
												<div className='draggerOR'>
													<h2>
														<span>OR</span>
													</h2>
												</div>
												<button className='draggerButton'>Browse files</button>
											</>
										)}
									</Dragger>
								</Tooltip>
							</div>
							<div className='ml-2'>
								{imageLoading && <Spin />}
								{nameforHighlight === 'imageISfile' && <CheckOutlined style={{ fontSize: '20px', color: 'green' }} />}
								{nameforError === 'imageISfile' && <CloseOutlined style={{ fontSize: '20px', color: 'red' }} />}
							</div>
						</div>
					</Col>
					<Col span={16}>
						<Row gutter={[12, 2]}>
							{chara.map((item, i) => {
								if (item.includes('break')) return getRestPlaceholder(i)
								return (
									<Col span={12} key={i}>
										<label className='character-stats-label'>{item[1]}</label>
										<Form.Item
											className={
												nameforHighlight === item[0]
													? 'highlighted'
													: nameforError === item[0]
													? 'highlighted-error'
													: null
											}
										>
											<Input
												suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
												name={item[0]}
												onBlur={handleBlur}
												placeholder={item[1]}
												defaultValue={loadedCharacter[`${item[0]}`]}
											/>
										</Form.Item>
									</Col>
								)
							})}
						</Row>
					</Col>
				</Row>

				<Row align='bottom' gutter={[12, 2]} className='mb-4'>
					{statsArr.map((item, i) => {
						if (item.includes('break')) return getRestPlaceholder(i)
						return (
							<Col key={i} span={24} sm={8} md={6}>
								<div>
									<label className='character-stats-label'>{item[1]}</label>
									<Form.Item
										className={
											nameforHighlight === item[0]
												? 'highlighted'
												: nameforError === item[0]
												? 'highlighted-error'
												: null
										}
									>
										<Input
											suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
											name={item[0]}
											onBlur={handleBlur}
											placeholder={item[1]}
											defaultValue={loadedCharacter[`${item[0]}`]}
										/>
									</Form.Item>
								</div>
							</Col>
						)
					})}
				</Row>
				<Row align='bottom' gutter={[12, 2]} className='mb-4'>
					{attributesArr.map((item, i) => {
						if (item.includes('break')) return getRestPlaceholder(i)
						return (
							<Col key={i} span={24} sm={8} md={6}>
								<div>
									<label className='character-stats-label'>{item[1]}</label>
									<Form.Item
										className={
											nameforHighlight === item[0]
												? 'highlighted'
												: nameforError === item[0]
												? 'highlighted-error'
												: null
										}
									>
										<Input
											suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
											name={item[0]}
											onBlur={handleBlur}
											placeholder={item[1]}
											defaultValue={loadedCharacter[`${item[0]}`]}
										/>
									</Form.Item>
								</div>
							</Col>
						)
					})}
				</Row>

				<Row align='bottom' gutter={[12, 2]} className='mb-4'>
					{bonusesArr.map((item, i) => {
						if (item.includes('break')) return getRestPlaceholder(i)
						return (
							<Col key={i} span={8}>
								<div>
									<label className='character-stats-label'>{item[1]}</label>
									<Form.Item
										className={
											nameforHighlight === item[0]
												? 'highlighted'
												: nameforError === item[0]
												? 'highlighted-error'
												: null
										}
									>
										<Input
											suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
											name={item[0]}
											onBlur={handleBlur}
											placeholder={item[1]}
											defaultValue={loadedCharacter[`${item[0]}`]}
										/>
									</Form.Item>
								</div>
							</Col>
						)
					})}
				</Row>

				<Row gutter={[12, 2]}>
					{textFlieds.map((item, i) => {
						if (item.includes('break')) return getRestPlaceholder(i)
						return (
							<Col key={i} span={12}>
								<label className='character-stats-label'>{item[1]}</label>
								<Form>
									<Form.Item
										className={
											nameforHighlight === item[0]
												? 'highlighted'
												: nameforError === item[0]
												? 'highlighted-error'
												: null
										}
									>
										<Input.TextArea
											suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
											autoSize={{ minRows: 2 }}
											name={item[0]}
											onBlur={handleBlur}
											placeholder={item[1]}
											defaultValue={loadedCharacter[`${item[0]}`]}
										/>
									</Form.Item>
								</Form>
							</Col>
						)
					})}
				</Row>

				<Row gutter={[12, 2]}>
					{largeTextFields.map((item) => (
						<Col span={24}>
							<label className='character-stats-label'>{item[1]}</label>
							<Form>
								<Form.Item
									className={
										nameforHighlight === item[0] ? 'highlighted' : nameforError === item[0] ? 'highlighted-error' : null
									}
								>
									<Input.TextArea
										suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
										autoSize={{ minRows: 2 }}
										name={item[0]}
										onBlur={handleBlur}
										placeholder={item[1]}
										defaultValue={loadedCharacter[`${item[0]}`]}
									/>
								</Form.Item>
							</Form>
						</Col>
					))}
				</Row>
			</div>
		)
	}
	return <h4>Character View</h4>
}

export default ChararcterStatsTab
