import React, { useState, useEffect } from 'react'
import EllipsisText from 'react-ellipsis-text'
import { Button, Spin, Row, Col, Card } from 'antd'
import { useSelector } from 'react-redux'

import { callToApi } from '../../utils'
import soundImage from './../../assets/soundImage.png'
import Notes from '../Notes'

function QuickNotesFiles() {
	const { data: dataGame } = useSelector((state) => state.gameData)
	const { session } = dataGame
	const { _id: scene_id, name } = dataGame.scene
	const [notesLoading, setNotesLoading] = useState(false)
	const [notesError, setNotesError] = useState('')
	const [notesList, setNotesList] = useState(null)

	const listFiles = async () => {
		setNotesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_notes',
			player_id: playerId,
			type: 'SCENE',
			scene_id: scene_id,
		}
		try {
			const { data } = await callToApi({ body })
			setNotesList(data.notes)
			setNotesLoading(false)
			setNotesError('')
		} catch (err) {
			setNotesLoading(false)
			setNotesError('Something went wrong.')
			console.log(err)
		}
	}

	const showFile = async (file) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'show_file',
			player_id: playerId,
			file_url: file,
		}
		try {
			const { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
		}
	}

	const hideFile = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'hide_file',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
			message.error('Error!!')
		}
	}

	const onFileClick = (file) => {
		if (session.display_fileISfile) {
			if (session.display_fileISfile === file) {
				hideFile()
			} else {
				showFile(file)
			}
		} else {
			showFile(file)
		}
	}

	useEffect(() => {
		listFiles()
	}, [])

	const notes = []
	const files = []

	if (notesList) {
		notesList.forEach((x) => {
			if (x.note_file) {
				files.push(x)
			} else {
				notes.push(x)
			}
		})
	}

	// console.log(notesList, files)

	if (notesList) {
		return (
			<>
				<div className='scrollable-lib' style={{ width: 650 }}>
					<h2 style={{ textAlign: 'center', color: '#fff', marginTop: 0, marginBottom: 15 }}>{name}</h2>
					<Notes noteType={'SCENE'} listOnly={true} scene_id={scene_id} />
					<Row gutter={[20, 20]}>
						{files.map((item) => (
							<Col span={8}>
								<Card
									style={{ minHeight: 258, minWidth: 180 }}
									bodyStyle={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
								>
									<div
										onClick={() => window.open(item.note_file, '_blank')}
										style={{
											width: '100%',
											backgroundImage: `url(${
												item.note_file?.slice(item.note_file?.lastIndexOf('.')) === '.mp3' ? soundImage : item.note_file
											})`,
										}}
										className={'new-lib-image-v2'}
									/>

									<div className='new-lib-title-v2 my-2'>
										<EllipsisText className='scene-title' text={item.note_file_title || ''} length={34} />
									</div>

									<div
										className={
											session.display_fileISfile === item.note_file
												? 'ant-btn ant-btn-dangerous'
												: 'ant-btn ant-btn-primary'
										}
										style={{ cursor: 'pointer' }}
										onClick={() => onFileClick(item.note_file)}
									>
										{session.display_fileISfile === item.note_file ? 'Remove' : 'Apply'}
									</div>
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</>
		)
	}

	if (notesLoading) {
		return (
			<>
				<div className='text-center my-3'>
					<Spin className='mr-2' /> loading Files ...
				</div>
			</>
		)
	}

	if (notesError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => listFiles()}>Try again</Button>
			</div>
		)
	}

	return <h4>Files</h4>
}

export default QuickNotesFiles
