import { SendOutlined } from '@ant-design/icons'
import { Button, Input, Form } from 'antd'
import React, { useState } from 'react'
import { callToApi } from './../../utils/index'

const MessageInput = ({ toPlayerNum }) => {
	const [messageInput, setMessageInput] = useState('')

	const sendChat = async (msg) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'send_chat',
			player_id: playerId,
			to_player_num: toPlayerNum,
			message: msg,
		}
		try {
			const { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
		}
	}

	const handleSubmit = () => {
		if (!messageInput) return
		sendChat(messageInput)
		setMessageInput('')
	}

	return (
		<>
			<div className='d-flex mb-1 align-end'>
				<div className='flex-grow-1'>
					<Input
						value={messageInput}
						className='messenger-chat-input'
						onChange={(e) => setMessageInput(e.target.value)}
						onPressEnter={handleSubmit}
						placeholder='Type your message...'
						suffix={
							<Button
								type='primary'
								shape='circle'
								onClick={handleSubmit}
								icon={<SendOutlined rotate={-38} style={{ fontSize: 12, marginRight: -3 }} />}
								style={{ borderRadius: '100%', width: 32, height: 32 }}
							/>
						}
					/>
				</div>
			</div>
		</>
	)
}

export default MessageInput
