import React from "react";
import SingleCharacter from "./SingleCharacter";
import { charaterColors } from "./../../utils/common";

const PlayerCharactersView = ({ dataGame, ui_images }) => {
  return (
    <div className="mb-2 characters-list-wrapper">
      <div className="d-flex align-stretch">
        <SingleCharacter
          dataGame={dataGame}
          type="player"
          playerBoxImg={ui_images.player_box_image}
          character={dataGame.players.find(
            (i) => i.playerNUM == dataGame.player.playerNUM
          )}
        />
        <div className="players-width d-flex flex-wrap">
          {dataGame.players.length &&
            dataGame.players.map((item, index) => {
              if (item.playerNUM == dataGame.player.playerNUM) {
                return null;
              } else {
                return (
                  <SingleCharacter
                    dataGame={dataGame}
                    type="oponent"
                    playerBoxImg={ui_images.player_box_image}
                    character={item}
                    characterColor={charaterColors[index]}
                  />
                );
              }
            })}
          <SingleCharacter
            dataGame={dataGame}
            type="dm_v2"
            playerBoxImg={ui_images.dm_box_image}
            character={{ playerNUM: 99 }}
            dmImage={ui_images.dm_image}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerCharactersView;
