const initialState = {
  muteStatus: false,
};

const backgroundMusicReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MUTE_UNMUTE":
      return { muteStatus: action.payload };
    default:
      return state;
  }
};
export default backgroundMusicReducer;
