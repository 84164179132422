import React, { useState } from 'react'
import { Form, Input, Button, message, Modal } from 'antd'
import { callToApi } from '../../utils'

const SignupForm = ({ ok, siginModal }) => {
	const [signupMsg, setSignupMsg] = useState('')

	const onFinish = async (values) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'signup',
			player_id: playerId,
			first_name: values.first_name,
			last_name: values.last_name,
			email: values.email,
			password: values.password,
		}

		try {
			const { data } = await callToApi({ body })
			setSignupMsg('')
			// message.success('Signup Succesfull')
			ok(true)
			siginModal()
		} catch (err) {
			setSignupMsg(
				`Oh dear!\n
        Signup failed
        Please try again.`
			)
			console.log(err)
		}
	}

	const onFinishFailed = (errorInfo) => {
		message.error('Signup Failed, Try again')
		console.log('Failed:', errorInfo)
	}

	const onBackClick = () => {
		ok()
		siginModal()
	}

	return (
		<div>
			<div style={{ marginBottom: 25 }}>
				<h1 className='mb-0'>Sign up</h1>
				<span>Please fill the form to create account!</span>
			</div>
			<Form layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item
					label='First Name'
					required={false}
					name='first_name'
					rules={[{ required: true, message: 'Please input your first name!' }]}
				>
					<Input placeholder='First name' />
				</Form.Item>
				<Form.Item
					label='Last Name'
					required={false}
					name='last_name'
					rules={[{ required: true, message: 'Please input your last name!' }]}
				>
					<Input placeholder='Last name' />
				</Form.Item>
				<Form.Item
					label='Email Address'
					required={false}
					name='email'
					rules={[{ required: true, message: 'Please input your email!' }]}
				>
					<Input placeholder='Email' />
				</Form.Item>

				<Form.Item
					label='Password'
					required={false}
					name='password'
					rules={[{ required: true, message: 'Please input your password!' }]}
				>
					<Input.Password placeholder='Password' />
				</Form.Item>
				<Form.Item
					name='confirm'
					label='Confirm Password'
					required={false}
					dependencies={['password']}
					rules={[
						{ required: true, message: 'Please confirm your password!' },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('The two passwords that you entered do not match!'))
							},
						}),
					]}
				>
					<Input.Password placeholder='Confirm password' />
				</Form.Item>

				<div style={{ marginTop: 20 }}>
					<div className='d-flex justify-space-between'>
						<Button type='primary' onClick={onBackClick}>
							Back
						</Button>
						<Button type='primary' htmlType='submit'>
							Sign up
						</Button>
					</div>
				</div>
			</Form>
			<Modal
				title='Signup Error'
				visible={signupMsg}
				onCancel={() => setSignupMsg('')}
				footer={null}
				style={{ top: '50%' }}
			>
				{signupMsg}
			</Modal>
		</div>
	)
}

export default SignupForm
