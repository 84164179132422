import { Col, Input, Row, Select, Form, Spin, message, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import React from 'react'
import Dragger from 'antd/lib/upload/Dragger'
import { CheckOutlined, CloseOutlined, EditOutlined, FolderOpenFilled } from '@ant-design/icons'

import keys from '../../config/keys'
import { callLoadGame, callToApi } from './../../utils/index'
import { setAppSettingsAction } from './../../redux/actions/appSettings/index'
import { setGameDataAction } from '../../redux/actions/gameData'

const { Option } = Select
const GameSettings = () => {
	const dispatch = useDispatch()
	const [imageLoading, setImageLoading] = useState(false)
	const [nameforHighlight, setNameforHighlight] = useState('')
	const [nameforError, setNameforError] = useState('')
	const [nameforLoading, setNameforLoading] = useState('')

	const { data } = useSelector((state) => state.appSettings)
	const { data: dataGame } = useSelector((state) => state.gameData)
	const { session } = dataGame
	const { game, rulesets } = data

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
			getAppSettings()
		} catch (err) {
			console.log(err)
		}
	}

	const getAppSettings = async () => {
		const body = { api_method: 'app_settings', app_version: '1.1', device_info: keys.deviceInfo }
		try {
			const { data } = await callToApi({ body })
			dispatch(setAppSettingsAction(data))
		} catch (err) {}
	}

	const makeHighlightSuccess = () => {
		setTimeout(() => {
			setNameforHighlight('')
		}, 2000)
	}

	const makeHighlightError = () => {
		setTimeout(() => {
			setNameforError('')
		}, 2000)
	}

	const editGameSettings = async (f, v) => {
		console.log(f, v)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'edit_game_settings',
			player_id: playerId,
			field: f,
			value: v,
		}
		try {
			const { data } = await callToApi({ body })
			console.log(data)
			setNameforLoading('')
			setNameforHighlight(f)
			makeHighlightSuccess()
			loadGame()
		} catch (err) {
			setNameforLoading('')
			setNameforError(f)
			makeHighlightError()
			console.log(err)
		}
	}

	const uploadImage = (file) => {
		var reader = new FileReader()
		if (file) {
			reader.readAsDataURL(file)
			reader.onload = () => {
				var Base64 = reader.result
				setImageLoading(true)
				updateCharacter('dm_imageISfile', Base64)
			}
			reader.onerror = (error) => {
				console.log('error: ', error)
			}
		}
	}

	const updateCharacter = async (f, v) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'edit_game_settings',
			player_id: playerId,
			field: f,
			value: v,
		}
		try {
			const { data } = await callToApi({ body })
			loadGame()
		} catch (err) {
			console.log(err)
		} finally {
			setImageLoading(false)
		}
	}

	const handleBlur = (e) => {
		if (e.target.value) {
			setNameforLoading(e.target.name)
			editGameSettings(e.target.name, e.target.value)
		}
	}

	const handleSelectPlayerNum = (val) => {
		setNameforLoading('number_of_playersNUM')
		editGameSettings('number_of_playersNUM', val)
	}
	const handleSelectVideoService = (value) => {
		setNameforLoading('video_service', value)
		editGameSettings('video_service', value)
	}
	const handleSelectGameType = (val) => {
		setNameforLoading('ruleset_name', val)
		editGameSettings('ruleset_name', val)
	}
	return (
		<div className='scrollable-one'>
			{game && (
				<Row gutter={[20, 20]} style={{ flexWrap: 'nowrap' }}>
					<Col>
						<div className='image-upload-custom mt-2 d-flex' style={{ position: 'relative' }}>
							<div>
								<Tooltip title={'Click or Drag and drop image here'} className='draggerTooltip'>
									<Dragger
										className={session.dm_imageISfile ? 'draggerrCharacterStats' : 'draggerrCharacterView'}
										multiple='false'
										maxCount={1}
										onRemove={() => message.info('not working right now')}
										beforeUpload={(file) => {
											uploadImage(file)
											return false
										}}
									>
										{session.dm_imageISfile ? (
											<>
												<div
													className='background-settings mx-2'
													style={{
														backgroundImage: `url(${session.dm_imageISfile})`,
														minHeight: '150px',
														backgroundSize: 'contain',
													}}
												></div>
												<EditOutlined className='edit-dp' />
											</>
										) : (
											<>
												<p className='ant-upload-drag-icon'>
													<FolderOpenFilled />
												</p>
												<p className='dragger-text my-25'>
													Drag your documents, photos, or videos here to start uploading.
												</p>
												<div className='draggerOR'>
													<h2>
														<span>OR</span>
													</h2>
												</div>
												<button className='draggerButton'>Browse files</button>
											</>
										)}
									</Dragger>
								</Tooltip>
							</div>
							<div className='ml-2'>
								{imageLoading && (
									<div className='dm-image-upload-loading'>
										<Spin />
									</div>
								)}
								{nameforHighlight === 'imageISfile' && <CheckOutlined style={{ fontSize: '20px', color: 'green' }} />}
								{nameforError === 'imageISfile' && <CloseOutlined style={{ fontSize: '20px', color: 'red' }} />}
							</div>
						</div>
					</Col>
					<Col flex={1}>
						<Form layout='vertical' initialValues={game}>
							<Row gutter={[20, 5]}>
								<Col span={24}>
									<Form.Item
										className={
											nameforHighlight === 'name' ? 'highlighted' : nameforError === 'name' ? 'highlighted-error' : null
										}
										label='Game Name'
										name='name'
									>
										<Input
											name='name'
											onBlur={handleBlur}
											placeholder='Game Name'
											suffix={nameforLoading === 'name' ? <Spin size='small' /> : null}
										/>
									</Form.Item>
								</Col>

								<Col span={24}>
									{/* <Form.Item
								className={
									nameforHighlight === 'video_service'
										? 'highlighted'
										: nameforError === 'video_service'
										? 'highlighted-error'
										: null
								}
								label='Video Service'
								name='video_service'
							>
								<Select
									onChange={handleSelectVideoService}
									name='video_service'
									defaultValue={game.video_service}
									suffix={nameforLoading === 'video_service' ? <Spin size='small' /> : null}
								>
									<Option value='integrated'>integrated API video</Option>
									<Option value='jitsi'>Jitsi</Option>
								</Select>
							</Form.Item> */}
								</Col>
								<Col span={24}>
									<Form.Item
										className={
											nameforHighlight === 'number_of_playersNUM'
												? 'highlighted'
												: nameforError === 'number_of_playersNUM'
												? 'highlighted-error'
												: null
										}
										label='Number of Players'
										name='number_of_playersNUM'
									>
										<Select
											onChange={handleSelectPlayerNum}
											name='number_of_playersNUM'
											suffix={nameforLoading === 'number_of_playersNUM' ? <Spin size='small' /> : null}
										>
											<Option value='1'>1</Option>
											<Option value='2'>2</Option>
											<Option value='3'>3</Option>
											<Option value='4'>4</Option>
											<Option value='5'>5</Option>
											<Option value='6'>6</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									{/* <Form.Item
								className={
									nameforHighlight === 'video_access_code'
										? 'highlighted'
										: nameforError === 'video_access_code'
										? 'highlighted-error'
										: null
								}
								label='Video Access Code'
								name='video_access_code'
							>
								<Input
									placeholder='Video Access Code'
									name='video_access_code'
									onBlur={handleBlur}
									suffix={nameforLoading === 'video_access_code' ? <Spin size='small' /> : null}
								></Input>
							</Form.Item> */}
								</Col>
								<Col span={24}>
									<Form.Item
										className={
											nameforHighlight === 'rulesets'
												? 'highlighted'
												: nameforError === 'rulesets'
												? 'highlighted-error'
												: null
										}
										label='Type of Game'
										name='rulesets'
									>
										<Select
											onSelect={handleSelectGameType}
											name='rulesets'
											defaultValue={game.ruleset_name}
											suffix={nameforLoading === 'rulesets' ? <Spin size='small' /> : null}
										>
											{rulesets.map((ruleset) => (
												<Option value={ruleset.name} key={ruleset._id}>{ruleset.name}</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			)}
		</div>
	)
}

export default GameSettings
