import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SingleCharacter from '../CharactersList/SingleCharacter'
import PlayerPopup from './PlayerPopup'
import './styles.css'

const PlayerItem = ({ handleMouseDown, playerItemData, dPosition, isDragging, isDraw }) => {
	const { player, players } = useSelector((state) => state.gameData.data)
	const { playerNUM } = player
	const { data } = useSelector((state) => state.appSettings)
	const { ui_images } = data
	const [playerPos, setPlayerPos] = useState({ x: 500, y: 500 })
	const [isPopOverVisible, setIsPopOverVisible] = useState(false)

	useEffect(() => {
		if (!isDragging) {
			setPlayerPos({
				x: playerItemData.posX,
				y: playerItemData.posY,
			})
		}
	}, [playerItemData])

	useEffect(() => {
		if (dPosition.name === playerItemData.name) {
			setPlayerPos({
				x: dPosition.posX,
				y: dPosition.posY,
			})
		}
	}, [dPosition])

	const dynamicHandling = () => {
		if (playerNUM === 99 || playerNUM == playerItemData.name) {
			handleMouseDown(playerItemData.name)
		}
	}

	const content = (
		<div>
			<PlayerPopup
				type='player'
				playerBoxImg={ui_images.player_box_image}
				character={players.find((i) => i.playerNUM == playerItemData.name)}
			/>
		</div>
	)

	return (
		<>
			<div className='map-player-popup' style={{ minWidth: '200px' }}>
				<Popover
					placement='right'
					content={content}
					overlayClassName='popupRight test-p'
					title='Player Stats'
					visible={isPopOverVisible && !isDragging && isDraw}
				>
					<div
						style={{
							top: playerPos.y,
							left: playerPos.x,
							backgroundColor: playerItemData.color,
							zIndex: '10',
							pointerEvents: !isDraw ? 'none' : 'all',
							border: '1px solid white',
							filter: 'drop-shadow(0px 0px 5px black)',
						}}
						onMouseOver={() => setIsPopOverVisible(true)}
						onMouseOut={() => setIsPopOverVisible(false)}
						onMouseDown={dynamicHandling}
						onMouseMove={(e) => e.stopPropagation()}
						className={`player-element ${player.playerNUM === 99 || playerNUM == playerItemData.name ? 'dm' : ''}`}
					>
						{playerItemData.name}
					</div>
				</Popover>
			</div>
		</>
	)
}

export default PlayerItem
