export const charaterColors = [
  "#4141be",
  "#9b42b8",
  "#d1b83c",
  "#2fb66b",
  "#d134cc",
  "#cd7100",
];

export const getDataUrl = (img) => {
  // Create canvas
  const canvas = Document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // Set width and height
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw the image
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL("image/jpeg");
};
