import React from 'react'
import SinglePlayerMessage from './SinglePlayerMessage'
import { Row, Tabs } from 'antd'
import { useSelector } from 'react-redux'
import { BellFilled } from '@ant-design/icons'
import { callToApi } from '../../utils'
const { TabPane } = Tabs

const DmMessageView = ({ dataGame, loadGame, initialActiveTabId }) => {
	const { chatListData } = useSelector((state) => state.chatList)

	const getAlerts = (id) => {
		const _alerts = dataGame?.chat_alerts?.[id] || []
		return _alerts.filter((item) => !item.seen)
	}

	async function markRead(key = '') {
		const [id, tab] = key.split('@')
		const alertsCount = getAlerts(id).length
		// console.log({ id, tab, alertsCount })
		if (!alertsCount) return loadGame()

		let queryP = new URLSearchParams(window.location.search)
		let gameId = queryP.get('g')
		let playerId = queryP.get('access')

		const body = {
			api_method: 'list_chats',
			apikey: process.env.REACT_APP_API_KEY,
			apisecret: process.env.REACT_APP_API_SECRET,
			game_id: gameId,
			player_id: playerId,
			player_tab_id: tab,
		}
		try {
			let { data } = await callToApi({ body })
			console.log('Mark seen response:', data)
		} catch (err) {
			console.log(err)
		} finally {
			loadGame()
		}
	}

	const getActiveKey = () => {
		if (!initialActiveTabId) return '98@'
		const index = dataGame.players.findIndex((x) => x.playerNUM === initialActiveTabId)
		return `${initialActiveTabId}@${index + 1}`
	}

	React.useEffect(() => {
		markRead(getActiveKey())
	}, [])

	return (
		<Tabs defaultActiveKey={getActiveKey()} onTabClick={(key) => markRead(key)}>
			<TabPane
				tab={
					<Row wrap={false} align='middle'>
						<span>Group</span>
						{getAlerts(98).length ? <BellFilled className='notification-bell-icon' /> : null}
					</Row>
				}
				key='98@'
			>
				<SinglePlayerMessage data={chatListData[98]} toPlayerNum={98} />
			</TabPane>
			{dataGame.players.map((player, index) => (
				<TabPane
					key={`${player.playerNUM}@${index + 1}`}
					tab={
						<Row wrap={false} align='middle'>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<span>{`Player `} </span>
								<span
									style={{
										backgroundColor: player.color,
										width: '23px',
										height: '23px',
										borderRadius: '50%',
										textAlign: 'center',
									}}
									className={'player-stats-num player-text white mb-0 ml-2'}
								>
									{player.playerNUM}
								</span>
							</div>
							{getAlerts(player.playerNUM).length ? <BellFilled className='notification-bell-icon' /> : null}
						</Row>
					}
				>
					<SinglePlayerMessage data={chatListData[player.playerNUM]} toPlayerNum={player.playerNUM} />
				</TabPane>
			))}
		</Tabs>
	)
}

export default DmMessageView
