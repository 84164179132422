import React, { useState, useEffect } from 'react'
import EllipsisText from 'react-ellipsis-text'
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons'
import { Button, Spin, message } from 'antd'

import { callToApi } from '../../utils'
import PrimaryModel from '../PrimaryModel'
import AddFileModal from './AddFileModal'

const Files = () => {
	const [notesLoading, setNotesLoading] = useState(false)
	const [notesError, setNotesError] = useState('')
	const [notesList, setNotesList] = useState(null)
	const [addFileModal, setAddFileModal] = useState(false)

	const listNotesFile = async () => {
		setNotesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_notes',
			player_id: playerId,
			type: 'GAME',
		}
		try {
			const { data } = await callToApi({ body })
			setNotesList(data.notes)
			setNotesLoading(false)
			setNotesError('')
		} catch (err) {
			setNotesLoading(false)
			setNotesError('something went wrong')
			console.log(err)
		}
	}

	const deleteNoteFile = async (id) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'delete_note',
			type: 'GAME',
			player_id: playerId,
			note_id: id,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('File Deleted')
			listNotesFile()
		} catch (err) {
			console.log(err)
			message.error('Action Failed')
		}
	}
	useEffect(() => {
		listNotesFile()
	}, [])

	const closeModal = () => {
		setAddFileModal(false)
	}

	if (notesList) {
		return (
			<>
				<div className='scrollable-one  pa-5'>
					<div className='d-flex flex-wrap' style={{ justifyContent: 'space-evenly' }}>
						{notesList.map((item) => (
							<>
								<div className='new-library-card-v2 standard-card'>
									<div className='box-1'>
										<div
											onClick={() => window.open(item.note_file, '_blank')}
											style={{
												backgroundImage: `url(${item.thumbnail})`,
												cursor: 'pointer',
											}}
											className={'new-lib-image-v2'}
										></div>
									</div>
									<div className='box-2'>
										<div className='new-lib-title-v2'>
											<EllipsisText className='scene-title' text={item.note_file_title || ''} length={34} />
										</div>

										<div className='new-lib-del-v2'>
											<div>
												<Button
													danger
													type='primary'
													shape='circle'
													onClick={() => deleteNoteFile(item.note_id)}
													icon={<DeleteOutlined />}
													size='small'
													className='action-card'
												/>
											</div>
										</div>
									</div>
								</div>
							</>
						))}

						<div className='new-library-card-v2 standard-card'>
							<div className='custom-add-button-wrap'>
								<Button type='primary' icon={<FileTextOutlined />} onClick={() => setAddFileModal(true)}>
									Add File
								</Button>
							</div>
						</div>
					</div>
				</div>

				<PrimaryModel handleCancel={() => setAddFileModal(false)} isModalVisible={addFileModal} title='Add File'>
					<AddFileModal listNotesFile={listNotesFile} closeModal={() => setAddFileModal(false)} />
				</PrimaryModel>
			</>
		)
	}
	if (notesLoading) {
		return (
			<>
				<div className='text-center my-3'>
					<Spin className='mr-2' /> loading Files ...
				</div>
			</>
		)
	}

	if (notesError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => listNotesFile()}>Try again</Button>
			</div>
		)
	}

	return <h4>Files</h4>
}

export default Files
