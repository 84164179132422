// **  Initial State
const initialState = {
  dmGlow: false,
};

const SecretRollGlow = (state = initialState, action) => {
  switch (action.type) {
    case "SECRET_ROLL_GLOW_ON":
      return { dmGlow: true };
    case "SECRET_ROLL_GLOW_OFF":
      return { dmGlow: false };
    default:
      return state;
  }
};

export default SecretRollGlow;
