export const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

export const getGameAndAccessId = () => {
	let queryP = new URLSearchParams(window.location.search)
	let gameId = queryP.get('g')
	let accessId = queryP.get('access')
	return { gameId, accessId }
}

export const getContainer = (node, property = 'parentElement') => node[property] ?? document.body
