import React from 'react'
import { Input, Button, Space, Spin, Alert, message, Popover, Tooltip, Modal } from 'antd'
import { useState } from 'react'
import { callToApi } from '../../utils'
import { CheckOutlined, StopOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import Notes from '../Notes/index'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const DmTab = ({ playerNum }) => {
	var accessLink = ''
	var playerName = ''
	const [loading, setLoading] = useState(false)
	const [showTick, setShowTick] = useState(false)
	const [showCross, setShowCross] = useState(false)
	const [emailState, setEmailState] = useState('')
	const [loadedCharacter, setLoadedCharacter] = useState(null)

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	const loadCharacter = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'load_character',
			player_id: playerId,
			playerNUM: playerNum,
		}
		try {
			const { data } = await callToApi({ body })
			setLoadedCharacter({ ...data })
			console.log(data)
		} catch (err) {
			console.log(err.response)
		}
	}
	const accessLinkModifer = () => {
		accessLink = loadedCharacter.player_access_link
		playerName = loadedCharacter.player_name || ''
		let temp = accessLink.replace('ww1.roleplaymagic.com', 'app.roleplaymagic.com')
		accessLink = temp
	}
	const makeHighlightSuccess = () => {
		setTimeout(() => {
			setShowTick(false)
		}, 2000)
	}

	const makeHighlightError = () => {
		setTimeout(() => {
			setShowCross(false)
		}, 2000)
	}

	const sendPlayerLink = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'send_player_link',
			player_id: playerId,
			playerNUM: playerNum,
			to_email: emailState,
		}
		try {
			const { data } = await callToApi({ body })
			setLoading(false)
			setShowTick(true)
			makeHighlightSuccess()
		} catch (err) {
			setLoading(false)
			setShowCross(true)
			makeHighlightError()
			console.log('error', err)
		}
	}

	const releaseCharacter = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'release_character',
			player_id: playerId,
			playerNUM: playerNum,
		}
		try {
			const { data } = await callToApi({ body })
			console.log(data)
			// message.success("Character Released");
			loadGame()
		} catch (err) {
			console.log(err)
			message.error('Failed to release, try again')
		}
	}

	const sendClick = () => {
		setLoading(true)
		emailState && sendPlayerLink()
	}

	const onEmailChange = (e) => {
		setEmailState(e.target.value)
	}

	useEffect(() => {
		loadCharacter()
	}, [])

	loadedCharacter && console.log(loadedCharacter)

	return (
		<>
			<div className='scrollable-one'>
				<div className='d-flex'>
					<div className='mb-4'>
						<Space className='align-end'>
							<div>
								<label>Player Email</label>
								<Input type='email' placeholder='Player Email' onChange={onEmailChange} value={emailState} />
							</div>
							<div>
								<Button type='primary' onClick={sendClick} disabled={loading || showTick || showCross}>
									{!loading && !showTick && !showCross && 'Send Link '}
									{loading && 'Loading '}
									{loading && <Spin />}
									{showTick && 'Sent '}
									{showTick && <CheckOutlined />}
									{showCross && 'Failed '}
									{showCross && <StopOutlined />}
								</Button>
							</div>
						</Space>
					</div>
					{playerName.length > 0 && (
						<div className='my-5 mx-15'>
							<Button
								onClick={() =>
									Modal.confirm({
										cancelButtonProps: { display: 'block' },
										okText: 'Yes',
										cancelText: 'No',
										title: `Please Confirm`,
										content: `Are you sure you want to release your character? This will exit you from the game!`,
										onOk: releaseCharacter,
									})
								}
							>
								Release Character
							</Button>
						</div>
					)}
				</div>
				<div className='mb-4'>
					<Space>
						<div>
							<label>Player Access Link</label>
							{loadedCharacter && (
								<CopyToClipboard
									text={loadedCharacter.player_access_link}
									onCopy={() => message.success('Copied to clipboard')}
								>
									<Tooltip placement='bottomRight' title={'Click to copy'}>
										<Alert message={loadedCharacter.player_access_link} type='info' style={{ cursor: 'pointer' }} />
									</Tooltip>
								</CopyToClipboard>
							)}
						</div>
					</Space>
				</div>

				<h5>DM Notes</h5>
				<Notes playerNum={playerNum} noteType={'DM-CHARACTER'} />
			</div>
		</>
	)
}

export default DmTab
