import React, { useEffect } from 'react'
import Pusher from 'pusher-js'
import { useDispatch, useSelector } from 'react-redux'

import View from './view'
import keys from './config/keys'
import logo from './assets/RolePlayMagic_black.png'
import { setChatListAction } from './redux/actions/chatList'
import { callLoadGame, callLogout, callToApi } from './utils'
import { setGameDataAction } from './redux/actions/gameData/index'
import { callAppSettingsAction } from './redux/actions/appSettings'
import { hidePopoverAction, showPopoverAction } from './redux/actions/characterPopover'
import { setAppSettingsAction, failedAppSettingsAction } from './redux/actions/appSettings/index'
import { SecretRollGlowOffAction, SecretRollGlowOnAction } from './redux/actions/characterPopover'

import './App.less'
import { getGameAndAccessId } from './utils/helpers'

function App() {
	const dispatch = useDispatch()
	const { loading, data, error } = useSelector((state) => state.appSettings)

	const getAppSettings = async () => {
		dispatch(callAppSettingsAction())
		const body = { api_method: 'app_settings', app_version: '1.1', device_info: keys.deviceInfo }

		try {
			const { data } = await callToApi({ body })
			console.log('App Settings:', data)
			dispatch(setAppSettingsAction(data))
		} catch (err) {
			dispatch(failedAppSettingsAction('Something Went wrong!'))
		}
	}

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			console.log({ data })
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	const loadChat = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = { api_method: 'list_chats', player_id: playerId }
		try {
			const { data } = await callToApi({ body })
			const { chats } = data
			dispatch(setChatListAction(chats))
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		const { gameId, accessId } = getGameAndAccessId()
		if (!gameId || !accessId) {
			const url = window.location.origin + window.location.pathname + `?g=${gameId || -1}&access=${accessId || -1}`
			window.location.href = url
			return
		}

		getAppSettings()
		if (gameId === '-1' || accessId === '-1') return

		const pusher = new Pusher('14a91a7963bbfc40580c', { cluster: 'eu' })
		const channel = pusher.subscribe(`roleplaymagic-${gameId}`)
		pusher.connection.bind('state_change', function (states) {
			if (states.current == 'disconnected') {
				console.log("You've been disconnected. Please reload this page...")
			} else {
				channel.bind(`data-transfer`, function (s) {
					console.log('PUSHER =>', s)
					if (s.update === 'chat') {
						loadChat()
						loadGame()
					}
					if (s.update === 'dice_roll') {
						if (s.dice_roll === '*') {
							loadGame().then(() => {
								dispatch(SecretRollGlowOnAction())
								setTimeout(() => {
									dispatch(SecretRollGlowOffAction())
								}, 5000)
							})
						} else {
							loadGame().then(() => {
								dispatch(showPopoverAction(s.player))
								setTimeout(() => {
									dispatch(hidePopoverAction(s.player))
								}, 5000)
							})
						}
					}
					if (s.update === 'scenes') {
						loadGame()
					}
					if (s.update === 'positions') {
						loadGame()
					}
					if (s.update === 'elements') {
						loadGame()
					}
					if (s.update === 'show_file') {
						loadGame()
					}
					if (s.update === 'game') {
						loadGame()
					}
				})
			}
		})
	}, [])

	const viewHelper = []

	if (loading) {
		viewHelper.push(
			<div key='loading' className='text-center mt-15'>
				<img src={logo} />
				<h1 className='mt-10'>Game Loading...</h1>
			</div>
		)
	} else {
		if (data) {
			viewHelper.push(<View key='view' />)
		} else if (error) {
			viewHelper.push(
				<div key='error' className='text-center mt-15'>
					<h3>{error}</h3>
				</div>
			)
		}
	}

	return <>{viewHelper}</>
}

export default App
