import {
  SET_CHAT_LIST,
  CALL_CHAT_LIST,
  REMOVE_CHAT_LIST,
} from "../../constants";

const initialState = {
  chatListLoading: false,
  chatListData: null,
};

const chatListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALL_CHAT_LIST:
      return { ...state, chatListLoading: true, chatListData: null };
    case SET_CHAT_LIST:
      return { ...state, chatListLoading: false, chatListData: action.payload };
    case REMOVE_CHAT_LIST:
      return { ...state, chatListLoading: false, chatListData: null };
    default:
      return state;
  }
};

export default chatListReducer;
