import React, { useState } from 'react'
import { Form, Input, Button, message, Image, Modal } from 'antd'
import { callToApi } from './../../utils/index'
import { useSelector } from 'react-redux'
import keys from '../../config/keys'

const NoAccessIdLoginForm = ({ ok, resetModal, signupSuccessful }) => {
	const [loginFailed, setLoginFailed] = useState('')
	const [loading, setLoading] = useState(false)

	const { game } = useSelector((state) => state.appSettings.data)

	const onFinish = async (values) => {
		const body = {
			api_method: 'login',
			login_email: values.email,
			login_password: values.password,
		}
		try {
			setLoading(true)
			const { data } = await callToApi({ body })
			console.log('No access id login form res:', data)
			setLoginFailed('')
			const session_id = data?.session_id || ''
			if (session_id) {
				localStorage.setItem(keys.sessionId, session_id)
			}
			ok(data)
		} catch (err) {
			setLoginFailed(
				`Oh dear!\n
        Login failed - incorrect password or email used.
        Please try again.`
			)
			console.log('error: ', err)
		} finally {
			setLoading(false)
		}
	}

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo)
	}

	const onForgotClick = () => {
		ok()
		resetModal()
	}

	return (
		<div>
			<div className='mb-5 text-center' style={{ display: 'flex', justifyContent: 'center' }}>
				<h1 className='mb-0' style={signupSuccessful ? { maxWidth: 350 } : {}}>
					{signupSuccessful ? 'Thanks for signing up, you can go ahead and login to start playing.' : 'Welcome to'}
				</h1>
				{!signupSuccessful && <h1>Roleplaymagic.com</h1>}
			</div>
			<Form layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item
					name='email'
					label='Email Address'
					required={false}
					rules={[{ required: true, message: 'Please input your email!' }]}
				>
					<Input placeholder='Email' />
				</Form.Item>

				<Form.Item
					name='password'
					label='Password'
					required={false}
					rules={[{ required: true, message: 'Please input your password!' }]}
				>
					<Input.Password placeholder='Password' visibilityToggle={false} />
				</Form.Item>

				<div style={{ marginTop: 20 }} className='d-flex justify-space-between'>
					<Button type='link' className='p-0' style={{ paddingLeft: 0, color: '#fff' }} onClick={onForgotClick}>
						Forgot Your Password?
					</Button>

					<Button loading={loading} type='primary' htmlType='submit'>
						Sign In
					</Button>
				</div>
			</Form>
			<Modal
				title='Login Error'
				visible={loginFailed}
				onCancel={() => setLoginFailed('')}
				footer={null}
				style={{ top: '50%' }}
			>
				{loginFailed}
			</Modal>
		</div>
	)
}

export default NoAccessIdLoginForm
