import React from 'react'
import { Tabs } from 'antd'
import { FileImageOutlined, FileOutlined, FileTextOutlined, SettingOutlined } from '@ant-design/icons'

import Scenes from './Scenes'
import Files from './Files'
import GameSettings from './GameSettings'
import Notes from './../Notes/index'

const { TabPane } = Tabs

const DmPopup = (props) => {
	return (
		<>
			<Tabs defaultActiveKey='1'>
				<TabPane
					tab={
						<>
							<SettingOutlined />
							Game Settings
						</>
					}
					key='1'
				>
					<GameSettings />
				</TabPane>
				<TabPane
					tab={
						<>
							<FileImageOutlined />
							Scenes
						</>
					}
					key='2'
				>
					<Scenes {...props} />
				</TabPane>
				<TabPane
					tab={
						<>
							<FileOutlined rotate={-180} />
							Notes
						</>
					}
					key='3'
				>
					<div className='scrollable-one'>
						<Notes noteType={'GAME'} />
					</div>
				</TabPane>
				{/* <TabPane
					tab={
						<>
							<FileTextOutlined />
							Files
						</>
					}
					key='4'
				>
					<Files />
				</TabPane> */}
			</Tabs>
		</>
	)
}

export default DmPopup
