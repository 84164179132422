import React, { useState, useEffect } from 'react'
import { FileImageOutlined } from '@ant-design/icons'
import { Button, Space, Row, Input, Spin, message } from 'antd'
import { callLoadGame, callToApi } from '../../utils'
import PrimaryModel from '../PrimaryModel'
import EditSceneView from './EditSceneView'
import { EditOutlined, DeleteOutlined, CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { setGameDataAction } from './../../redux/actions/gameData/index'
import EditSceneModal from './EditSceneModal'
import EllipsisText from 'react-ellipsis-text'

const Scenes = ({ closeParentModel }) => {
	const [applying, setApplying] = useState(null)
	const [addMode, setAddMode] = useState(false)
	const [scenesLoading, setScenesLoading] = useState(false)
	const [scenesError, setScenesError] = useState('')
	const [scenes, setScenes] = useState(null)
	const [editSceneModal, setEditSceneModal] = useState(false)
	const [sceneEdit, setSceneEdit] = useState(null)
	const [addSceneLoading, setAddSceneLoading] = useState(false)

	const dispatch = useDispatch()

	const { data: dataGame } = useSelector((state) => state.gameData)

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}
	const loadScenes = async () => {
		setScenesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_scenes',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			setScenes(data.scenes)
			console.log('Scenes:',data.scenes)
			setScenesLoading(false)
			setScenesError('')
		} catch (err) {
			setScenesLoading(false)
			setScenesError('something went wrong')
			console.log('error')
			console.log(err)
		}
	}

	const deleteScene = async (id) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'delete_scene',
			player_id: playerId,
			scene_id: id,
		}
		try {
			const { data } = await callToApi({ body })
			loadScenes()
			// message.success('Scene Deleted')
		} catch (err) {
			console.log('error')
			console.log(err)
			message.error('Action Failed')
		}
	}

	const addScene = async () => {
		setAddSceneLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'add_scene',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			loadScenes()
			setAddSceneLoading(false)
			setSceneEdit(data)
			setEditSceneModal(true)
		} catch (err) {
			setAddSceneLoading(false)
			message.error('Action Failed')
			console.log('error')
			console.log(err)
		} finally {
			setAddMode(true)
		}
	}

	const applyScene = async (sceneId) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'apply_scene',
			scene_id: sceneId,
			player_id: playerId,
		}
		try {
			setApplying(sceneId)
			const { data } = await callToApi({ body })
			loadGame()
			// message.success('Scene Applied')
			setApplying(null)
			closeParentModel()
		} catch (err) {
			setApplying(null)
			console.log('error', err)
			message.error('Action Failed')
		}
	}

	useEffect(() => {
		loadScenes()
	}, [])

	const onSceneClick = (scene) => {
		setSceneEdit(scene)
		setEditSceneModal(true)
	}

	if (scenes) {
		return (
			<>
				<div className='scrollable-lib'>
					<div className='d-flex flex-wrap' style={{ justifyContent: 'space-evenly' }}>
						{scenes.map((item) => (
							<div className='new-library-card-v2 standard-card'key={item._id}>
								<div className='box-1'>
									<div
										style={{ backgroundImage: `url(${item.imageISfile + '?random_number=' + new Date().getTime()})` }}
										className={'new-lib-image-v2'}
									></div>
								</div>
								<div className='box-2'>
									<div className='new-lib-title-v2'>
										<EllipsisText className='scene-title' text={item.name || ''} length={34} />
									</div>

									<div>
										<div className='status'>
											{item._id === dataGame.scene._id ? (
												<div className='applied-badge'>Applied</div>
											) : (
												<Button
													block
													type='primary'
													loading={applying === item._id}
													onClick={() => {
														applyScene(item._id)
													}}
												>
													{applying === item._id ? '' : 'Apply'}
												</Button>
											)}
										</div>
									</div>

									{/* <div className='new-lib-del-v2'> */}
									<Row justify='end' wrap={false}>
										<Button
											type='link'
											shape='circle'
											onClick={() => onSceneClick(item)}
											icon={<EditOutlined />}
											size='small'
											className='action-card'
											style={{ marginRight: 2 }}
										/>
										<Button
											danger
											type='link'
											shape='circle'
											onClick={() => deleteScene(item._id)}
											icon={<DeleteOutlined />}
											size='small'
											className='action-card'
										/>
									</Row>
									{/* </div> */}
								</div>
							</div>
						))}

						<div className='new-library-card-v2 standard-card'>
							<div className='custom-add-button-wrap'>
								<Button loading={addSceneLoading} type='primary' icon={<FileImageOutlined />} onClick={addScene}>
									Add Scene
								</Button>
								{/* <button className='custom-add-button' onClick={addScene} disabled={addSceneLoading}>
									Add Scene {addSceneLoading ? <Spin /> : null}
								</button> */}
							</div>
						</div>
					</div>
				</div>

				<PrimaryModel
					handleCancel={() => setEditSceneModal(false)}
					isModalVisible={editSceneModal}
					title={addMode ? 'Add new scene' : 'Edit Scene'}
					afterClose={() => setAddMode(false)}
					bodyStyle={{ paddingBottom: 0, paddingTop: 5 }}
				>
					<EditSceneModal
						applyScene={applyScene}
						sEdit={sceneEdit}
						isApplying={applying === sceneEdit?._id}
						loadScenes={loadScenes}
						closeParentModel={() => {
							setEditSceneModal(false)
							closeParentModel?.()
						}}
					/>
				</PrimaryModel>
			</>
		)
	}
	if (scenesLoading) {
		return (
			<>
				<div className='text-center my-3'>
					<Spin className='mr-2' /> loading Scenes ...
				</div>
			</>
		)
	}

	if (scenesError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => loadScenes()}>Try again</Button>
			</div>
		)
	}

	return <h4>Scenes</h4>
}

export default Scenes

/* <div
                  style={{ backgroundImage: `url(${item.imageISfile})` }}
                  className={"imageBox background-settings"}
                >
                  <div className="text-center scene-title-wrap">
                    <h2 className="scene-title">{item.name}</h2>
                  </div>

                  <div className="image-action-buttons">
                    <Button
                      type="default"
                      onClick={() => onSceneClick(item)}
                      icon={<EditFilled />}
                      size="small"
                    />
                    <Button
                      onClick={() => deleteScene(item._id)}
                      icon={<DeleteOutlined />}
                      size="small"
                    />
                  </div>
                  {item._id === dataGame.scene._id ? (
                    <div className="apply-scene-wrap-tick-mark">
                      <CheckCircleOutlined
                        style={{
                          color: "lime",
                          marginLeft: "4px",
                          marginTop: "4px",
                          fontSize: "16px",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="apply-scene-wrap">
                      <Button
                        type="default"
                        onClick={() => {
                          applyScene(item._id);
                        }}
                        size="small"
                      >
                        Apply
                      </Button>
                    </div>
                  )}
                </div> */
