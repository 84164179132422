import { Button, Col, Form, Input, Row, Space, Spin, Tooltip } from 'antd'
import { Upload, message } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Dropzone from 'react-dropzone'
import { useState } from 'react'
import { FileDrop } from 'react-file-drop'
import './Demo.css'
import { callToApi } from './../../utils/index'
import Dragger from 'antd/lib/upload/Dragger'
import ImageDragger from '../ImageDragger/ImageDragger'

const EditSceneAddFileModal = ({ listNotesFile, modalClose, scene_id, listNotes }) => {
	const [uploadedFile, setUploadedFile] = useState(null)
	const [imageLoading, setImageLoading] = useState(false)
	const [fileUploading, setFileUploading] = useState(false)

	const addNote = async (name, data) => {
		setFileUploading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'add_note',
			type: 'SCENE',
			player_id: playerId,
			file_name: name,
			file_data: data,
			scene_id: scene_id,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('File Added Successfully!')
			setFileUploading(false)
			listNotes()
			modalClose()
		} catch (err) {
			console.log(err)
			message.error('Error while uploading file, Try again')
			setFileUploading(false)
		}
	}

	const test = (e) => {
		addNote(e.file_name, uploadedFile)
	}

	const uploadImage = (file) => {
		var reader = new FileReader()
		if (file) {
			reader.readAsDataURL(file)
			reader.onload = () => {
				var Base64 = reader.result
				setUploadedFile(Base64)
			}
			reader.onerror = (error) => {
				console.log('error: ', error)
			}
		}
	}
	return (
		<div className='scrollable-one'>
			<Form onFinish={test} layout='vertical'>
				<Row gutter={[20, 5]} align='bottom'>
					<Col xs={24} md={12} lg={10}>
						<Form.Item
							name='file_name'
							label='File Name'
							rules={[{ whitespace: true, required: true, message: `Please input a file name.` }]}
						>
							<Input placeholder='File Name' />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 5]} align='bottom'>
					<Col span={24}>
						<Form.Item
							name='file_data'
							rules={[{ required: uploadedFile ? false : true, message: 'Please input your File!' }]}
						>
							<ImageDragger
								uploadImage={uploadImage}
								uploadedFile={uploadedFile}
								imageLoading={imageLoading}
								editElement={false}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 5]} align='bottom'>
					<Col xs={24} md={24} lg={24}>
						<Form.Item>
							<Button
								loading={fileUploading}
								type='primary'
								htmlType='submit'
								disabled={uploadedFile === null || fileUploading}
							>
								Submit
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

export default EditSceneAddFileModal
