import React, { useState } from 'react'
import PrimaryModel from '../PrimaryModel'
import LibraryPopup from '../LibraryPopup'
import MessengerPopup from '../MessengerPopup'
import AudioVideoPopup from '../AudioVideoPopup'
import { callLoadGame, callLogout, callToApi } from '../../utils'
import HeaderButtons from './HeaderButtons'
import { useDispatch, useSelector } from 'react-redux'
import { removeGameDataAction } from '../../redux/actions/gameData'
import DmPopup from './../DmPopup'
import DmCharactersView from './../CharactersList/DmCharactersView'
import PlayerCharactersView from './../CharactersList/PlayerCharactersView'
import DiceList from '../Dice'
import { Switch } from 'antd'
import NewsPanelPopup from '../NewsPanelPopup'
import { setGameDataAction } from './../../redux/actions/gameData/index'

const GameFunctions = ({ ui_images, dataGame }) => {
	const [isLibraryModelVisible, setIsLibraryModelVisible] = useState(false)
	const [isDmModelVisible, setIsDmModelVisible] = useState(false)
	const [isMessengerModelVisible, setIsMessengerModelVisible] = useState(false)
	const [isNewsModelVisible, setIsNewsModelVisible] = useState(false)
	const [secretRollStatus, setSceretRollStatus] = useState(0)
	const [isMinimize, setIsMinimize] = useState(false)
	const [isVideoPopUp, setIsVideoPopUp] = useState(false)
	const [isaudioVideoModelVisible, setIsAudioVideoModelVisible] = useState(false)

	const { data } = useSelector((state) => state.appSettings)

	const { api_url } = data
	const dispatch = useDispatch()

	let windowP = new URLSearchParams(window.location.search)
	let gameIdIframe = windowP.get('g')
	let playerIdIframe = windowP.get('access')

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	const logout = async () => {
		try {
			let { data } = await callLogout()
			dispatch(removeGameDataAction())
			window.location.replace('https://' + api_url)
		} catch (err) {
			console.log(err)
		}
	}
	const secretRollCLick = (checked) => {
		setSceretRollStatus(checked)
	}
	const releaseCharacter = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'release_character',
			player_id: playerId,
			playerNUM: dataGame.player.playerNUM,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('Character Released')
			loadGame()
			logout()
		} catch (err) {
			console.log(err)
			message.error('Failed to release, try again')
		}
	}
	return (
		<>
			<div className='game-actions h-100'>
				<div className='mb-2'>
					<HeaderButtons
						setIsDmModelVisible={setIsDmModelVisible}
						setIsLibraryModelVisible={setIsLibraryModelVisible}
						logout={logout}
						ui_images={ui_images}
						dataGame={dataGame}
						releaseCharacter={releaseCharacter}
						setIsMessengerModelVisible={setIsMessengerModelVisible}
						setIsNewsModelVisible={setIsNewsModelVisible}
						// these methods for AudiVideo logic from FooterButtons component
						// setIsAudioVideoModelVisible={setIsAudioVideoModelVisible}
						// setIsMinimize={setIsMinimize}
					/>
				</div>
				{dataGame ? (
					<>
						{dataGame.player.playerNUM === 99 ? (
							<DmCharactersView
								dataGame={dataGame}
								ui_images={ui_images}
								secret_roll={secretRollStatus}
								setIsDmModelVisible={setIsDmModelVisible}
							/>
						) : (
							<PlayerCharactersView dataGame={dataGame} ui_images={ui_images} />
						)}
					</>
				) : null}
				<div className='mb-2'>
					<div className='d-flex align-center'>
						<DiceList secret_roll={secretRollStatus} />
						<div className='flex-grow-1'>
							{dataGame ? (
								<>
									{dataGame.player.playerNUM === 99 && (
										<div className='d-flex justify-end mb-3'>
											<span className='white mr-2'>Secret Roll</span>
											<Switch
												// checkedChildren={<CheckOutlined />}
												// unCheckedChildren={<CloseOutlined />}
												className='secret-roll-switch'
												onChange={secretRollCLick}
												style={{
													// backgroundColor: 'transparent',
													border: '1px solid #cecece',
												}}
											/>
										</div>
									)}
								</>
							) : null}
						</div>
					</div>
				</div>

				{!isaudioVideoModelVisible && !isMinimize && (
					<>
						{dataGame && (
							<div
								dangerouslySetInnerHTML={{
									__html: `<iframe id=myframe style=border:none;border-radius:20px;min-height:240px;background:transparent  allow=camera;microphone  width=600 src='https://video.blueboxonline.co.uk/room/${gameIdIframe}/${playerIdIframe}' />`,
								}}
							/>
						)}
					</>
				)}
				{/* {console.log(gameIdIframe, playerIdIframe)} */}
			</div>

			<PrimaryModel
				handleCancel={() => setIsLibraryModelVisible(false)}
				isModalVisible={isLibraryModelVisible}
				title='Library'
				bodyStyle={{ overflowY: 'scroll', height: '525px' }}
			>
				<LibraryPopup />
			</PrimaryModel>
			<PrimaryModel
				handleCancel={() => setIsDmModelVisible(false)}
				isModalVisible={isDmModelVisible}
				title='Dungeon Master'
				bodyStyle={{ paddingBottom: 0, paddingTop: 5 }}
			>
				<DmPopup closeParentModel={() => setIsDmModelVisible(false)} />
			</PrimaryModel>

			<PrimaryModel
				handleCancel={() => {
					setIsMessengerModelVisible(false)
					loadGame()
				}}
				isModalVisible={isMessengerModelVisible}
				title='Messenger'
				bodyStyle={{ paddingTop: 0, paddingBottom: 10 }}
				afterClose={() => localStorage.removeItem('ActiveMessengerTabId')}
			>
				<MessengerPopup loadGame={loadGame} dataGame={dataGame} />
			</PrimaryModel>

			{isaudioVideoModelVisible && (
				<>
					{!isMinimize && (
						<div
							className='videoContainerMask'
							style={{
								position: 'fixed',
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
								zIndex: 1000,
								height: '100%',
								transition: 'ease-in-out 0.3s',
								backgroundColor: 'rgba(0, 0, 0, 0.45)',
							}}
						/>
					)}
					<div
						className='videoContainer'
						style={{
							position: 'fixed',
							top: '50px',
							zIndex: isMinimize ? -1 : 1000,
							background: 'white',
							width: '800px',
							paddingTop: '35px',
							opacity: isMinimize ? 0 : 1,
							transition: 'ease-out 0.1s',
						}}
					>
						<div
							className='minimizeBtn'
							style={{
								position: 'absolute',
								right: '10px',
								top: '0px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<button
								onClick={() => setIsMinimize(true)}
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
									marginRight: '20px',
									marginBottom: '12px',
								}}
							>
								{' '}
								<span class='material-icons'>minimize</span>
							</button>
							<button
								onClick={() => setIsAudioVideoModelVisible(false)}
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
								}}
							>
								{' '}
								<span class='material-icons'>close</span>
							</button>
						</div>
						<AudioVideoPopup />
					</div>
				</>
			)}
			<PrimaryModel
				bodyStyle={{ maxHeight: '500px', overflowY: 'scroll' }}
				handleCancel={() => setIsNewsModelVisible(false)}
				isModalVisible={isNewsModelVisible}
				title='News'
			>
				<NewsPanelPopup />
			</PrimaryModel>
		</>
	)
}

export default GameFunctions
