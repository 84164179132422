import React from 'react'
import { useState, useEffect } from 'react'
import { Spin, Button } from 'antd'
import { callToApi } from '../../utils'
import MessageInput from './MessageInput'
import SingleMessage from './SingleMessage'
import { useSelector } from 'react-redux'

const SinglePlayerMessage = ({ toPlayerNum, data }) => {
	return (
		<div>
			<div className='scrollable-two d-flex flex-column-reverse'>
				{data ? (
					<>
						{data.map((item) => (
							<div key={item.time}>
								{' '}
								<SingleMessage toPlayerNum={toPlayerNum} msg={item} />
							</div>
						))}
					</>
				) : (
					<div className='text-center' style={{ position: 'absolute', top: '170px', right: '300px' }}>
						<h3>Type a message below to start a chat</h3>
					</div>
				)}
			</div>
			<div className='d-flex fill-height align-end mt-4'>
				<div className='flex-grow-1 px-10'>
					<MessageInput toPlayerNum={toPlayerNum} />
				</div>
			</div>
		</div>
	)
}

export default SinglePlayerMessage
