import {
  CALL_APP_SETTINGS,
  FAILED_APP_SETTINGS,
  SET_APP_SETTINGS,
} from "../../constants";

// **  Initial State
const initialState = {
  loading: false,
  data: null,
  error: null,
};

const appSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALL_APP_SETTINGS:
      return { ...state, loading: true, data: null, error: null };
    case SET_APP_SETTINGS:
      return { ...state, loading: false, error: null, data: action.payload };
    case FAILED_APP_SETTINGS:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
};

export default appSettingsReducer;
