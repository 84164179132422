import React from 'react'
import { Tabs } from 'antd'
import { FileImageOutlined, FileTextOutlined } from '@ant-design/icons'

import EditSceneFilesView from './EditSceneFilesView'
import EditSceneView from './EditSceneView'
const { TabPane } = Tabs

const EditSceneModal = (props) => {
	const { sEdit } = props
	return (
		<>
			<Tabs defaultActiveKey='1'>
				<TabPane
					tab={
						<>
							<FileImageOutlined />
							Scene
						</>
					}
					key='1'
				>
					<EditSceneView {...props} />
				</TabPane>
				<TabPane
					tab={
						<>
							<FileTextOutlined />
							Files
						</>
					}
					key='2'
				>
					<EditSceneFilesView {...props} scene_id={sEdit._id} />
				</TabPane>
			</Tabs>
		</>
	)
}

export default EditSceneModal
