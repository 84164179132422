import React, { useState, useEffect } from 'react'
import EllipsisText from 'react-ellipsis-text'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spin, message, Row, Col, Card } from 'antd'

import { callLoadGame, callToApi } from '../../utils'
import { setGameDataAction } from './../../redux/actions/gameData/index'

const QuickScenes = ({ setQuickEditScreen }) => {
	const [applying, setApplying] = useState(null)
	const [scenesLoading, setScenesLoading] = useState(false)
	const [scenesError, setScenesError] = useState('')
	const [scenes, setScenes] = useState(null)

	const dispatch = useDispatch()

	const { data: dataGame } = useSelector((state) => state.gameData)

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	const loadScenes = async () => {
		setScenesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_scenes',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			setScenes(data.scenes)
			console.log(data.scenes)
			setScenesLoading(false)
			setScenesError('')
		} catch (err) {
			setScenesLoading(false)
			setScenesError('something went wrong')
			console.log('error')
			console.log(err)
		}
	}

	const applyScene = async (sceneId) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'apply_scene',
			scene_id: sceneId,
			player_id: playerId,
		}
		try {
			setApplying(sceneId)
			const { data } = await callToApi({ body })
			loadGame()
			// message.success('Scene Applied')
			setApplying(null)
		} catch (err) {
			setApplying(null)
			console.log('error', err)
			message.error('Action Failed')
		}
	}

	useEffect(() => {
		loadScenes()
	}, [])

	if (scenes) {
		return (
			<>
				<div className='scrollable-lib' style={{ width: 650 }}>
					<Row justify='space-between' gutter={[20, 20]}>
						{scenes.map((item) => (
							<Col span={8}>
								<Card
									onClick={() => {
										setQuickEditScreen(null)
										window.focus()
										setTimeout(() => setQuickEditScreen(item), 500)
									}}
									style={{ minHeight: 258, minWidth: 180, cursor: 'pointer' }}
									bodyStyle={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
								>
									<div
										style={{
											width: '100%',
											backgroundImage: `url(${item.imageISfile + '?random_number=' + new Date().getTime()})`,
										}}
										className={'new-lib-image-v2'}
									/>
									<div className='new-lib-title-v2 my-2'>
										<EllipsisText className='scene-title' text={item.name || ''} length={34} />
									</div>

									<div className='status'>
										{item._id === dataGame.scene._id ? (
											<div className='applied-badge' style={{ cursor: 'auto' }} onClick={(e) => e.stopPropagation()}>
												Applied
											</div>
										) : (
											<Button
												type='primary'
												loading={applying === item._id}
												onClick={(e) => {
													e.stopPropagation()
													applyScene(item._id)
												}}
												style={{ minWidth: 71 }}
											>
												{applying === item._id ? '' : 'Apply'}
											</Button>
										)}
									</div>
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</>
		)
	}

	if (scenesLoading) {
		return (
			<>
				<div className='text-center my-3'>
					<Spin className='mr-2' /> loading Scenes ...
				</div>
			</>
		)
	}

	if (scenesError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => loadScenes()}>Try again</Button>
			</div>
		)
	}

	return <h4>Quick Scenes</h4>
}

export default QuickScenes
