import React from 'react'
import { Tabs, Row, Col } from 'antd'
import { FileOutlined } from '@ant-design/icons'
import { FaGratipay, FaToolbox } from 'react-icons/fa'

import ChararcterStatsTab from './ChararcterStatsTab'
import PluginsTab from './PluginsTab'
import DmTab from './DmTab'
import NotesTab from './NotesTab'
import InventoryTab from './InventoryTab'

const { TabPane } = Tabs

const CharacterPopup = ({ isDm, character_sheet, playerNum }) => {
	return (
		<>
			<Tabs defaultActiveKey='1'>
				<TabPane
					tab={
						<Row align='middle' gutter={[8, 0]}>
							<Col>
								<FaGratipay style={{ verticalAlign: 'middle' }} />
							</Col>
							<Col>Statistics</Col>
						</Row>
					}
					key='1'
				>
					<ChararcterStatsTab playerNum={playerNum} character_sheet={character_sheet} isDm={isDm} />
				</TabPane>

				{!isDm && (
					<TabPane
						tab={
							<>
								<FileOutlined rotate={-180} />
								Notes
							</>
						}
						key='2'
					>
						<div className='scrollable-one'>
							<NotesTab playerNum={playerNum} character_sheet={character_sheet} isDm={isDm} />
						</div>
					</TabPane>
				)}

				<TabPane
					tab={
						<Row align='middle' gutter={[8, 0]}>
							<Col>
								<FaToolbox style={{ verticalAlign: 'middle' }} />
							</Col>
							<Col>Inventory</Col>
						</Row>
					}
					key='3'
				>
					<div className='scrollable-one'>
						<InventoryTab playerNum={playerNum} character_sheet={character_sheet} isDm={isDm} />
					</div>
				</TabPane>

				{isDm ? (
					<TabPane tab='DM' disabled={!isDm} key='4'>
						<DmTab playerNum={playerNum} />
					</TabPane>
				) : null}
			</Tabs>
		</>
	)
}

export default CharacterPopup
