import { Modal, Button } from 'antd'
import React from 'react'

import ModalBGImage from '../../assets/gaston_final.png'
import logo from '../../assets/RolePlayMagic_white.png'

const LoginSignUpModal = ({
	className = '',
	wrapClassName = '',
	isModalVisible,
	children,
	title,
	handleCancel,
	footer,
	maskClosable = true,
	style = {},
	bodyStyle = {},
}) => {
	return (
		<>
			<div>
				<Modal
					className={className}
					wrapClassName={`${wrapClassName} custom-modal-login`}
					title={title}
					maskClosable={maskClosable}
					visible={isModalVisible}
					footer={footer}
					onCancel={handleCancel}
					maskStyle={{
						backgroundImage: `url('${ModalBGImage}')`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center center',
					}}
					style={style}
					bodyStyle={{ paddingBottom: 12, ...bodyStyle }}
				>
					{children}
				</Modal>
			</div>
		</>
	)
}

export default LoginSignUpModal
