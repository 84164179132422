import React, { useEffect, useState } from 'react'
import { Form, Col, Row, Image, Button, InputNumber, Spin, Upload, Space, Select, Switch, Tooltip } from 'antd'
import Notes from './../Notes/index'
import { useDispatch, useSelector } from 'react-redux'
import { callLoadGame, callToApi } from '../../utils'
import {
	ArrowUpOutlined,
	CheckCircleOutlined,
	CheckOutlined,
	CloseOutlined,
	CompressOutlined,
	DownOutlined,
	EditOutlined,
	FolderOpenFilled,
	StopOutlined,
} from '@ant-design/icons'
import { Input } from 'antd'
import { setGameDataAction } from '../../redux/actions/gameData'
import Dragger from 'antd/lib/upload/Dragger'
import ImageDragger from '../ImageDragger/ImageDragger'
const { Option } = Select

const { TextArea } = Input

const EditElement = ({ elementObj, handleCancel }) => {
	const [nameforHighlight, setNameforHighlight] = useState('')
	const [nameforError, setNameforError] = useState('')
	const [nameforLoading, setNameforLoading] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [elementLoading, setElementLoading] = useState(false)
	const [elementError, setElementError] = useState('')
	const [loadedElement, setLoadedElement] = useState(null)

	useEffect(() => {
		loadElement()
	}, [])

	const loadElement = async () => {
		setElementLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'load_element',
			player_id: playerId,
			elementNUM: elementObj.elementNUM,
		}
		try {
			const { data } = await callToApi({ body })
			setLoadedElement({ ...data })
			setElementLoading(false)
			setElementError('')
		} catch (err) {
			setElementError('something went wrong')
			setElementLoading(false)
			console.log(err.response)
		}
	}

	const [deleteButton, setDeleteButton] = useState({
		loading: false,
		showTick: false,
		showCross: false,
	})
	const [deadButton, setDeadButton] = useState({
		loading: false,
		showTick: false,
		showCross: false,
	})

	const [aliveButton, setAliveButton] = useState({
		loading: false,
		showTick: false,
		showCross: false,
	})
	const dispatch = useDispatch()

	const btnStyle = {
		minWidth: '50px',
		borderRadius: '5px',
		justifyContent: 'center',
	}
	const aliveBtnStyle = {
		minWidth: '50px',
		borderRadius: '5px',
		justifyContent: 'center',
		backgroundColor: 'green',
	}

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}
	const makeHighlightSuccess = () => {
		setTimeout(() => {
			setNameforHighlight('')
		}, 2000)
	}

	const makeHighlightError = () => {
		setTimeout(() => {
			setNameforError('')
		}, 2000)
	}

	const allFalse = () => {
		setTimeout(() => {
			setDeleteButton({ loading: false, showTick: false, showCross: false })
			setDeadButton({ loading: false, showTick: false, showCross: false })
			setAliveButton({ loading: false, showTick: false, showCross: false })
		}, 2000)
	}
	const deleteElement = async () => {
		setDeleteButton({ ...deleteButton, loading: true })
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'delete_element',
			player_id: playerId,
			elementNUM: elementObj.elementNUM,
		}
		try {
			const { data } = await callToApi({ body })
			setDeleteButton({ ...deleteButton, loading: false, showTick: true })
			allFalse()
			handleCancel()
		} catch (err) {
			setDeleteButton({ ...deleteButton, loading: false, showCross: true })
			allFalse()
			console.log(err)
		}
	}
	const updateElement = async (f, v) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'update_element',
			player_id: playerId,
			elementNUM: elementObj.elementNUM,
			field: f,
			value: v,
		}
		try {
			const { data } = await callToApi({ body })
			setLoadedElement({ ...data })
			setNameforLoading('')
			setNameforHighlight(f)
			makeHighlightSuccess()
			setImageLoading(false)
		} catch (err) {
			setImageLoading(false)
			setNameforLoading('')
			setNameforError(f)
			makeHighlightError()
			console.log('error', err)
		}
	}
	const deadElement = async () => {
		setDeadButton({ ...deadButton, loading: true })
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'update_element',
			player_id: playerId,
			elementNUM: elementObj.elementNUM,
			field: 'deadYN',
			value: '1',
		}
		try {
			const { data } = await callToApi({ body })
			setDeadButton({ ...deadButton, loading: false, showTick: true })
			allFalse()
			setLoadedElement({ ...data })
		} catch (err) {
			setDeadButton({ ...deadButton, loading: false, showCross: true })
			allFalse()
			console.log(err)
		}
	}

	const aliveElement = async () => {
		setAliveButton({ ...aliveButton, loading: true })
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'update_element',
			player_id: playerId,
			elementNUM: elementObj.elementNUM,
			field: 'deadYN',
			value: '0',
		}
		try {
			const { data } = await callToApi({ body })
			setAliveButton({ ...aliveButton, loading: false, showTick: true })
			allFalse()

			setLoadedElement({ ...data })
		} catch (err) {
			setAliveButton({ ...aliveButton, loading: false, showCross: true })
			allFalse()
			console.log(err)
		}
	}

	const handleBlur = (e) => {
		if (e.target.value) {
			setNameforLoading(e.target.name)
			updateElement(e.target.name, e.target.value)
		}
	}
	const handleTypeChange = (value) => {
		updateElement('element_type', value)
		setNameforLoading('element_type')
	}
	const uploadImage = (file) => {
		var reader = new FileReader()
		if (file) {
			reader.readAsDataURL(file)
			reader.onload = () => {
				var Base64 = reader.result
				setImageLoading(true)
				updateElement('imageISfile', Base64)
			}
			reader.onerror = (error) => {
				console.log('error: ', error)
			}
		}
	}

	const secretRollCLick = (checked) => {
		let check
		if (checked == true) {
			check = 0
		} else if (checked == false) {
			check = 1
		}
		updateElement('viewable_by_dm_onlyYN', check)
	}

	if (elementLoading) {
		return (
			<div className='text-center my-3'>
				<Spin className='mr-2' /> loading element ...
			</div>
		)
	}

	if (elementError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => loadElement()}>Try again</Button>
			</div>
		)
	}

	if (loadedElement) {
		return (
			<>
				<Form layout='vertical' initialValues={loadedElement}>
					<Row gutter={8}>
						<Col span={5}>
							<Form.Item
								label='Type'
								name='element_type'
								className={
									nameforHighlight === 'element_type'
										? 'highlighted'
										: nameforError === 'element_type'
										? 'highlighted-error'
										: null
								}
							>
								<Select
									name='element_type'
									style={{ width: '100%' }}
									onChange={handleTypeChange}
									defaultValue={loadedElement.element_type}
								>
									<Option value='npc'>Opponent/NPC</Option>
									<Option value='item'>Item</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label='Element Name'
								name='name'
								className={
									nameforHighlight === 'name' ? 'highlighted' : nameforError === 'name' ? 'highlighted-error' : null
								}
							>
								<Input
									autoComplete='off'
									placeholder='Name'
									name='name'
									onBlur={handleBlur}
									suffix={nameforLoading === 'name' ? <Spin size='small' /> : null}
								/>
							</Form.Item>
						</Col>
						<Col span={1}></Col>
						<Col span={3}>
							<Form.Item
								label='AC'
								name='ac'
								className={
									nameforHighlight === 'ac' ? 'highlighted' : nameforError === 'ac' ? 'highlighted-error' : null
								}
							>
								<Input
									placeholder='AC'
									name='ac'
									onBlur={handleBlur}
									suffix={nameforLoading === 'ac' ? <Spin size='small' /> : null}
								/>
							</Form.Item>
						</Col>
						<Col span={3}>
							<Form.Item
								label='HP'
								name='hp'
								className={
									nameforHighlight === 'hp' ? 'highlighted' : nameforError === 'hp' ? 'highlighted-error' : null
								}
							>
								<Input
									placeholder='HP'
									name='hp'
									onBlur={handleBlur}
									suffix={nameforLoading === 'hp' ? <Spin size='small' /> : null}
								/>
							</Form.Item>
						</Col>
						<Col span={3}>
							<Form.Item
								label='HP MAX'
								name='hp_max'
								className={
									nameforHighlight === 'hp_max' ? 'highlighted' : nameforError === 'hp_max' ? 'highlighted-error' : null
								}
							>
								<Input
									placeholder='HP MAX'
									name='hp_max'
									onBlur={handleBlur}
									suffix={nameforLoading === 'hp_max' ? <Spin size='small' /> : null}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={20}>
						<Col span={12}>
							<ImageDragger
								className='mb-2'
								loadedElement={loadedElement}
								uploadImage={uploadImage}
								imageLoading={imageLoading}
								editElement={true}
							/>
							<div className='mb-2'>
								{imageLoading && <Spin />}
								{nameforHighlight === 'imageISfile' && <CheckOutlined style={{ fontSize: '20px', color: 'green' }} />}
								{nameforError === 'imageISfile' && <CloseOutlined style={{ fontSize: '20px', color: 'red' }} />}
							</div>
						</Col>

						<Col>
							<div className='d-flex'>
								<span className='mr-3'>
									<h4>Hide</h4>
								</span>
								<span>
									<Switch
										defaultChecked={loadedElement.viewable_by_dm_onlyYN == 1 ? false : true}
										onChange={secretRollCLick}
										style={{
											backgroundColor: 'gray',
											border: '1px solid #cecece',
										}}
									/>
								</span>
								<span className='mr-3'>
									<h4>Show</h4>
								</span>
							</div>

							<Row>
								<div className='my-4'>
									<div>
										<p>Element Size</p>
									</div>
									<div className='d-flex'>
										<span>
											<InputNumber
												className={
													nameforHighlight === 'heightNUM'
														? 'highlighted'
														: nameforError === 'heightNUM'
														? 'highlighted-error'
														: null
												}
												suffix={nameforLoading === 'heightNUM' ? <Spin size='small' /> : null}
												min={1}
												max={10}
												name='heightNUM'
												style={{ width: '48px' }}
												defaultValue={loadedElement.heightNUM}
												onBlur={handleBlur}
											/>
										</span>

										{nameforLoading === 'heightNUM' ? <Spin size='small' /> : null}
										<span>
											<h3 className='mx-3'>X</h3>
										</span>
										<span>
											<InputNumber
												className={
													nameforHighlight === 'widthNUM'
														? 'highlighted'
														: nameforError === 'widthNUM'
														? 'highlighted-error'
														: null
												}
												suffix={nameforLoading === 'widthNUM' ? <Spin size='small' /> : null}
												min={1}
												max={10}
												name='widthNUM'
												defaultValue={loadedElement.widthNUM}
												style={{ width: '48px' }}
												controls={true}
												onBlur={handleBlur}
											/>
										</span>
										{nameforLoading === 'widthNUM' ? <Spin size='small' /> : null}
									</div>
								</div>
							</Row>
						</Col>
					</Row>

					<Form.Item
						label='Notes'
						name='notesISsmallplaintextbox'
						className={
							nameforHighlight === 'notesISsmallplaintextbox'
								? 'highlighted'
								: nameforError === 'notesISsmallplaintextbox'
								? 'highlighted-error'
								: null
						}
					>
						{/* <Input
                  autoComplete="off"
                  placeholder="Notes"
                  name="notesISsmallplaintextbox"
                  onBlur={handleBlur}
                  suffix={
                    nameforLoading === "name" ? <Spin size="small" /> : null
                  }
                /> */}
						<TextArea
							rows={8}
							autoComplete='off'
							placeholder='Notes'
							name='notesISsmallplaintextbox'
							onBlur={handleBlur}
							suffix={nameforLoading === 'name' ? <Spin size='small' /> : null}
						/>
					</Form.Item>

					<Row gutter={[10, 0]} wrap={false}>
						<Col flex={1}>
							<Row gutter={[10, 0]} wrap={false}>
								<Col>
									<Row>
										<Col>
											{loadedElement.element_type !== 'item' && loadedElement.deadYN == '0' && (
												<Button
													type='primary'
													danger
													style={btnStyle}
													color={'black'}
													onClick={deadElement}
													disabled={deleteButton.loading || deadButton.loading}
												>
													{!deadButton.loading && !deadButton.showCross && !deadButton.showTick && 'Dead'}
													{deadButton.loading && 'Loading'}
													{deadButton.loading && <Spin />}
													{deadButton.showTick && 'Dead'}
													{deadButton.showTick && <CheckOutlined />}
													{deadButton.showCross && 'Failed'}
													{deadButton.showCross && <StopOutlined />}
												</Button>
											)}
											{loadedElement.deadYN == '1' && (
												<Button type='primary' style={aliveBtnStyle} color={'black'} onClick={aliveElement}>
													{!aliveButton.loading && !aliveButton.showCross && !aliveButton.showTick && 'Alive'}
													{aliveButton.loading && 'Loading'}
													{aliveButton.loading && <Spin />}
													{aliveButton.showTick && 'Alive'}
													{aliveButton.showTick && <CheckOutlined />}
													{aliveButton.showCross && 'Failed'}
													{aliveButton.showCross && <StopOutlined />}
												</Button>
											)}
										</Col>
									</Row>
								</Col>
								<Col>
									<Row>
										<Button
											type='primary'
											danger
											style={btnStyle}
											onClick={deleteElement}
											disabled={deleteButton.loading || deadButton.loading}
										>
											{!deleteButton.loading && !deleteButton.showCross && !deleteButton.showTick && 'Delete'}
											{deleteButton.loading && 'Loading'}
											{deleteButton.loading && <Spin />}
											{deleteButton.showTick && 'Deleted'}
											{deleteButton.showTick && <CheckOutlined />}
											{deleteButton.showCross && 'Failed'}
											{deleteButton.showCross && <StopOutlined />}
										</Button>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col>
							<Button type='primary' onClick={handleCancel}>
								Save
							</Button>
						</Col>
					</Row>
				</Form>
			</>
		)
	}
	return <h4>Element View</h4>
}

export default EditElement
