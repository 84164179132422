import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PrimaryModel from './../PrimaryModel/index'
import EditElement from './EditElement'
import ElementPopup from './ElementPopup'
import itemImg from '../../assets/images.png'
const ElementItem = ({ elementItemData, dataGame, handleMouseDownElement, ePosition, isDragging, isDraw }) => {
	const [IsModalVisible, setIsModalVisible] = useState(false)
	const [displayStyle, setDisplayStyle] = useState('block')
	const [elementPos, setElementPos] = useState({ x: 500, y: 500 })
	const [popoverState, setPopoverState] = useState(false)
	const [displaySelect, setDisplaySelect] = useState(false)
	const [elementObj, setElementObj] = useState(null)
	const [elWidth, setElWidth] = useState(null)
	const [isPopOverVisible, setIsPopOverVisible] = useState(false)
	const { data } = useSelector((state) => state.appSettings)
	const { ui_images } = data
	const { elements, player } = dataGame

	useEffect(() => {
		const ele = elements.find((i) => i.elementNUM === elementItemData.name)
		setElementObj({ ...ele })
	}, [dataGame])

	useEffect(() => {
		if (!isDragging) {
			setElementPos({
				x: elementItemData.posX,
				y: elementItemData.posY,
			})
			if (elementItemData.posY > 760) {
				setDisplaySelect(true)
			}
		}
	}, [elementItemData])

	useEffect(() => {
		if (ePosition.name === elementItemData.name) {
			setElementPos({
				x: ePosition.posX,
				y: ePosition.posY,
			})
		}
	}, [ePosition])

	const dynamicHandling = () => {
		if (player.playerNUM === 99) {
			handleMouseDownElement(elementItemData.name)
		}
	}

	let d = 'flex'
	if (elementPos.y > 760) {
		if (player.playerNUM !== 99) {
			d = 'none'
		}
	}
	const handleDoubleClick = () => {
		if (player.playerNUM === 99) {
			setIsModalVisible(true)
		}
	}
	const content = (
		<div>
			<ElementPopup ui_images={ui_images} elementObj={elementObj} playerBoxImg={ui_images.player_box_image} />
		</div>
	)

	let viewHelper = []

	if (elementObj && elementObj.deadYN == 1) {
		if (elementObj?.viewable_by_dm_onlyYN == 1 && player.playerNUM == 99) {
			viewHelper.push(
				<div
					style={{
						top: elementPos.y,
						left: elementPos.x,
						backgroundImage: `url(${data.ui_images.skeleton_image})`,
						userSelect: 'none',
						display: d,
						zIndex: '11',
						pointerEvents: !isDraw ? 'none' : 'all',
						border: '2px dotted white',

						filter: 'drop-shadow(0px 0px 5px black)',
					}}
					onDoubleClick={handleDoubleClick}
					onMouseDown={dynamicHandling}
					onMouseMove={(e) => e.stopPropagation()}
					className='element-dead background-settings'
				>
					{elementObj.elementNUM}
				</div>
			)
		} else if (elementObj?.viewable_by_dm_onlyYN == 0) {
			viewHelper.push(
				<div
					style={{
						top: elementPos.y,
						left: elementPos.x,
						backgroundImage: `url(${data.ui_images.skeleton_image})`,
						userSelect: 'none',
						display: d,
						zIndex: '11',
						pointerEvents: !isDraw ? 'none' : 'all',
						border: '1px solid white',

						filter: 'drop-shadow(0px 0px 5px black)',
					}}
					onDoubleClick={handleDoubleClick}
					onMouseDown={dynamicHandling}
					onMouseMove={(e) => e.stopPropagation()}
					className='element-dead background-settings'
				>
					{elementObj.elementNUM}
				</div>
			)
		}
	} else if (elementObj && elementObj.element_type == 'item') {
		if (elementObj?.viewable_by_dm_onlyYN == 1 && player.playerNUM == 99) {
			viewHelper.push(
				<Popover
					content={content}
					placement='right'
					title={'Element Stats'}
					overlayClassName='test-p'
					visible={isPopOverVisible && !isDragging}
				>
					<div
						style={{
							top: elementPos.y,
							left: elementPos.x,
							display: d,
							zIndex: '11',
							pointerEvents: !isDraw ? 'none' : 'all',
							filter: 'drop-shadow(0px 0px 5px black)',
							border: '2px dotted white',
						}}
						onDoubleClick={handleDoubleClick}
						onMouseDown={dynamicHandling}
						onMouseMove={(e) => e.stopPropagation()}
						className={`element ${player.playerNUM === 99 ? 'dm' : ''}`}
					>
						{elementObj.imageISfile ? (
							<img
								draggable={false}
								src={elementObj.imageISfile}
								width={elementObj.widthNUM * 15}
								height={elementObj.heightNUM * 15}
								style={{ userSelect: 'none' }}
							/>
						) : (
							elementObj && elementObj.elementNUM
						)}
					</div>
				</Popover>
			)
		} else if (elementObj?.viewable_by_dm_onlyYN == 0) {
			viewHelper.push(
				<Popover
					content={content}
					placement='right'
					title={'Element Stats'}
					overlayClassName='test-p'
					visible={isPopOverVisible && !isDragging}
				>
					<div
						style={{
							top: elementPos.y,
							left: elementPos.x,
							display: d,
							zIndex: '11',
							pointerEvents: !isDraw ? 'none' : 'all',
							filter: 'drop-shadow(0px 0px 5px black)',
							border: '1px solid white',
						}}
						onDoubleClick={handleDoubleClick}
						onMouseDown={dynamicHandling}
						onMouseMove={(e) => e.stopPropagation()}
						className={`element ${player.playerNUM === 99 ? 'dm' : ''}`}
					>
						{elementObj.imageISfile ? (
							<img
								draggable={false}
								src={elementObj.imageISfile || ui_images.generic_monster}
								width={elementObj.widthNUM * 15}
								height={elementObj.heightNUM * 15}
								style={{ userSelect: 'none' }}
							/>
						) : (
							elementObj && elementObj.elementNUM
						)}
					</div>
				</Popover>
			)
		}
	} else {
		if (elementObj?.viewable_by_dm_onlyYN == 1 && player.playerNUM == 99) {
			viewHelper.push(
				<Popover
					content={content}
					title={'Element Stats'}
					overlayClassName='test-p'
					placement='right'
					visible={isPopOverVisible && !isDragging && isDraw}
				>
					<div
						style={{
							top: elementPos.y,
							left: elementPos.x,
							height: `${Number(elementObj?.heightNUM) * 9}px`,
							width: `${Number(elementObj?.widthNUM) * 9}px`,
							minHeight: '20px',
							minWidth: '20px',
							display: d,
							zIndex: '11',
							border: '2px dotted white',
							filter: 'drop-shadow(0px 0px 5px black)',
							pointerEvents: !isDraw ? 'none' : 'all',
						}}
						onMouseOver={() => setIsPopOverVisible(true)}
						onMouseOut={() => setIsPopOverVisible(false)}
						onDoubleClick={handleDoubleClick}
						onMouseDown={dynamicHandling}
						onMouseMove={(e) => e.stopPropagation()}
						className={`element ${player.playerNUM === 99 ? 'dm' : ''}`}
					>
						{elementObj && elementObj.elementNUM}
					</div>
				</Popover>
			)
		} else if (elementObj?.viewable_by_dm_onlyYN == 0) {
			viewHelper.push(
				<Popover
					content={content}
					title={'Element Stats'}
					overlayClassName='test-p'
					placement='right'
					visible={isPopOverVisible && !isDragging && isDraw}
				>
					<div
						style={{
							top: elementPos.y,
							left: elementPos.x,
							height: `${Number(elementObj?.heightNUM) * 9}px`,
							width: `${Number(elementObj?.widthNUM) * 9}px`,
							minHeight: '20px',
							minWidth: '20px',
							display: d,
							zIndex: '11',
							border: '1px solid white',
							filter: 'drop-shadow(0px 0px 5px black)',
							pointerEvents: !isDraw ? 'none' : 'all',
						}}
						onMouseOver={() => setIsPopOverVisible(true)}
						onMouseOut={() => setIsPopOverVisible(false)}
						onDoubleClick={handleDoubleClick}
						onMouseDown={dynamicHandling}
						onMouseMove={(e) => e.stopPropagation()}
						className={`element ${player.playerNUM === 99 ? 'dm' : ''}`}
					>
						{elementObj && elementObj.elementNUM}
					</div>
				</Popover>
			)
		}
	}

	return (
		<>
			{elementObj && viewHelper}
			<PrimaryModel
				isModalVisible={IsModalVisible}
				title={'Edit Element'}
				handleCancel={() => setIsModalVisible(false)}
			>
				<EditElement elementObj={elementObj} handleCancel={() => setIsModalVisible(false)} />
			</PrimaryModel>
		</>
	)
}

export default ElementItem
