import React from 'react'

import Notes from '../Notes/index'

export default function NotesTab({ character_sheet, playerNum, isDm }) {
	return (
		<>
			{/* <label className='character-stats-label'>Player Notes</label> */}
			<Notes listOnly={isDm} playerNum={playerNum} noteType={'CHARACTER'} />
		</>
	)
}
