import { Row, Button, Spin, Col, Card, notification, message } from 'antd'
import React, { useEffect, useState } from 'react'
import EllipsisText from 'react-ellipsis-text'
import { useDispatch } from 'react-redux'

import keys from '../config/keys'
import NewGameModal from './NewGameModal'
import { callLoadGame, callToApi } from '../utils'
import LoginSignUpModal from '../components/LoginSignupModal'

const titleStyles = { textAlign: 'center', color: '#fff', marginBottom: 20 }

function GameListModal(props) {
	const { loggedInData, logOff, reusing, currentGameId } = props
	const [gameData, setGameData] = useState(null)
	const [loadGameFailed, setLoadGameFailed] = useState(false)
	const [loadingGameData, setLoadingGameData] = useState(true)
	const [newGameModal, setNewGameModal] = useState(false)

	const loadGame = async () => {
		try {
			setLoadingGameData(true)
			setLoadGameFailed(false)
			const sessionId = loggedInData?.session_id || localStorage.getItem(keys.sessionId) || undefined
			let { data } = await callLoadGame({ player_id: sessionId, game_id: '-1' })
			console.log('Game data:', data)
			setGameData(data)
			if (data.login_required === 1) {
				const { alert_text, alert_title } = data
				notification.error({ message: alert_title, description: alert_text })
			}
		} catch (err) {
			console.log(err)
			setLoadGameFailed(true)
		} finally {
			setLoadingGameData(false)
		}
	}

	useEffect(() => {
		loadGame()
	}, [])

	if (loadingGameData) {
		return (
			<Row justify='center' className='mt-5'>
				<Spin />
			</Row>
		)
	}

	if (loadGameFailed) {
		return (
			<Row justify='center' className='mt-5'>
				<Button onClick={loadGame}>Try Again</Button>
			</Row>
		)
	}

	if (!gameData) return null
	const { my_games, playing } = gameData

	const getItem = (item, index) => {
		const g = item._shortcode
		const access = item.dm_access_key || item.player_access_key
		let isPlaying = false
		if (currentGameId && currentGameId === g) {
			isPlaying = true
		}

		return (
			<Col span={6} key={index}>
				<Card
					style={{ minHeight: 258, minWidth: 180 }}
					bodyStyle={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
				>
					<div
						style={{
							width: '100%',
							backgroundSize: 'contain',
							backgroundImage: `url(${item.imageISfile || item.dm_imageISfile})`,
						}}
						className={'new-lib-image-v2'}
					/>

					<div className='new-lib-title-v2 my-2'>
						<EllipsisText className='scene-title' text={item.name || item.game_name || ''} length={20} />
					</div>

					<Button
						className=''
						type='primary'
						disabled={isPlaying}
						onClick={async () => {
							let hide
							try {
								if (!g || !access) return
								hide = message.loading('Logging out from current game...', 0)
								if (reusing) await logOff()
								const url = window.location.origin + `?g=${g}&access=${access}`
								window.location.href = url
							} catch (e) {
								console.log(e)
							} finally {
								hide && hide()
							}
						}}
						style={isPlaying ? { backgroundColor: '#009688', color: '#fff' } : {}}
					>
						{isPlaying ? 'Playing' : 'Play'}
					</Button>
				</Card>
			</Col>
		)
	}

	const listEmpty = my_games?.length === 0 && playing?.length === 0
	const newGameBtn = (
		<Button type='primary' onClick={() => setNewGameModal(true)}>
			New Game
		</Button>
	)

	return (
		<>
			<Row align='middle' justify='space-between'>
				<Col>{!listEmpty && newGameBtn}</Col>
				{!reusing && (
					<Col>
						<Button type='primary' onClick={logOff}>
							Log Off
						</Button>
					</Col>
				)}
			</Row>

			{listEmpty && (
				<>
					<Row style={{ height: '90%' }} align='middle'>
						<Col span={24}>
							<Row justify='center'>
								<h1 style={{ maxWidth: 470, textAlign: 'center' }}>
									It looks like you have no games yet, lets get started and add your first game!
								</h1>
							</Row>
						</Col>
						<Col span={24} style={{ marginTop: -200 }}>
							<Row justify='center'>{newGameBtn}</Row>
						</Col>
					</Row>
				</>
			)}

			{my_games?.length > 0 ? (
				<div className='mb-5'>
					<h2 style={titleStyles}>Your DM Games</h2>
					<Row gutter={[20, 20]}>{my_games.map(getItem)}</Row>
				</div>
			) : null}

			{playing?.length > 0 ? (
				<>
					<h2 style={titleStyles}>Your Player Games</h2>
					<Row gutter={[20, 20]}>{playing.map(getItem)}</Row>
				</>
			) : null}

			<LoginSignUpModal
				handleCancel={() => setNewGameModal(false)}
				isModalVisible={newGameModal}
				title={null}
				footer={null}
				bodyStyle={{ paddingTop: 5 }}
			>
				<NewGameModal {...props} />
			</LoginSignUpModal>
		</>
	)
}

export default GameListModal
