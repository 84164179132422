import { FileOutlined, FolderOpenFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Space, Spin, Tooltip } from 'antd'
import { Upload, message } from 'antd'
import React from 'react'
import { useState } from 'react'
import './Demo.css'
import { getBase64 } from '../../utils/helpers'
import { callToApi } from './../../utils/index'
import Dragger from 'antd/lib/upload/Dragger'
import ImageDragger from '../ImageDragger/ImageDragger'

const AddFileModal = ({ listNotesFile, closeModal }) => {
	const [uploadedFile, setUploadedFile] = useState(null)
	const [imageLoading, setImageLoading] = useState(false)
	const [fileUploading, setFileUploading] = useState(false)

	const addNote = async (name, data) => {
		console.log('inside add note')
		setFileUploading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'add_note',
			type: 'GAME',
			player_id: playerId,
			file_name: name,
			file_data: data,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('File Added Successfully!')
			listNotesFile()
			setFileUploading(false)
			closeModal()
		} catch (err) {
			console.log(err)
			setFileUploading(false)
			message.error('Error while uploading file, Try again')
		}
	}

	const test = (e) => {
		addNote(e.file_name, uploadedFile)
	}

	const uploadImage = async (file) => {
		if (file) {
			try {
				const base64 = await getBase64(file)
				setUploadedFile(base64)
			} catch (e) {
				console.log(e)
			}
		}
	}
	return (
		<div className='scrollable-one'>
			<Form onFinish={test} layout='vertical'>
				<Row gutter={[20, 5]} align='bottom'>
					<Col xs={24} md={12} lg={10}>
						<Form.Item
							name='file_name'
							label='File Name'
							rules={[{ whitespace: true, required: true, message: `Please input a file name.` }]}
						>
							<Input placeholder='File Name' />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 5]} align='bottom'>
					<Col span={24}>
						<Form.Item name='file_data'>
							<ImageDragger
								uploadImage={uploadImage}
								uploadedFile={uploadedFile}
								imageLoading={imageLoading}
								editElement={false}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 5]} align='bottom'>
					<Col xs={24} md={24} lg={24}>
						<Form.Item>
							<Button
								loading={fileUploading}
								type='primary'
								htmlType='submit'
								disabled={uploadedFile === null || fileUploading}
							>
								Submit
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

export default AddFileModal
