import React from 'react'
import { useSelector } from 'react-redux'
import SingleDice from './SingleDice'

const DiceList = ({ secret_roll }) => {
	const settingsData = useSelector((state) => state.appSettings.data)
	const dice = settingsData?.dice || []
	return dice.map((singleDiceData) => (
		<div style={{ position: 'relative' }} key={singleDiceData.dice_code}>
			<SingleDice singleDiceData={singleDiceData} secret_roll={secret_roll} />
		</div>
	))
}

export default DiceList
