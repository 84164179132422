import { Col, Input, Row, Select, Form, Spin, notification, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import React from 'react'
import { callToApi } from '../utils'

const forItemStyle = { marginBottom: 12 }

function NewGameModal(props) {
	const { loggedInData } = props
	const { data } = useSelector((state) => state.appSettings)
	const { rulesets = [] } = data || {}
	const [creating, setCreating] = React.useState(false)

	const submitForm = async (values) => {
		const sessionId = loggedInData?.session_id || localStorage.getItem(keys.sessionId) || undefined
		const body = { api_method: 'add_game', player_id: sessionId, game_id: '-1', ...values }
		try {
			setCreating(true)
			console.log('PostData to add new game:', body)
			const { data } = await callToApi({ body })
			console.log('New game add response:', data)
			const { new_game = {}, error } = data || {}
			if (error) {
				return notification.error({ message: 'Error', description: error || 'Something went wrong!' })
			}
			const url = window.location.origin + `?g=${new_game.code}&access=${new_game.dm_access_key}`
			window.location.href = url
		} catch (err) {
			console.log(err)
			notification.error({ message: 'Error', description: err.message || 'Something went wrong!' })
		} finally {
			setCreating(false)
		}
	}

	return (
		<>
			<div style={{ marginBottom: 30, marginTop: 40 }}>
				<h1 style={{ textAlign: 'center', lineHeight: 1.1 }} className='mb-0'>
					Create a new game
				</h1>
				<h1 style={{ textAlign: 'center' }}>and discover a new world</h1>
			</div>

			<Form layout='vertical' onFinish={submitForm}>
				<Row>
					<Col span={24}>
						<Form.Item
							label='Game Title'
							name='name'
							style={forItemStyle}
							rules={[{ required: true, message: 'Please provide game title!' }]}
						>
							<Input placeholder='Game Title...' />
						</Form.Item>
					</Col>

					<Col span={24}>
						{/* <Form.Item
                  label='Video Service'
                  name='video_service'
                >
                  <Select
                    onChange={handleSelectVideoService}
                    name='video_service'
                    defaultValue={game.video_service}
                    suffix={nameforLoading === 'video_service' ? <Spin size='small' /> : null}
                  >
                    <Select.Option value='integrated'>integrated API video</Select.Option>
                    <Select.Option value='jitsi'>Jitsi</Select.Option>
                  </Select>
                </Form.Item> */}
					</Col>
					<Col span={24}>
						<Form.Item
							label='Number of Players'
							name='number_of_playersNUM'
							style={forItemStyle}
							rules={[{ required: true, message: 'Please select number of players!' }]}
						>
							<Select>
								<Select.Option value='1'>1</Select.Option>
								<Select.Option value='2'>2</Select.Option>
								<Select.Option value='3'>3</Select.Option>
								<Select.Option value='4'>4</Select.Option>
								<Select.Option value='5'>5</Select.Option>
								<Select.Option value='6'>6</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						{/* <Form.Item
                  label='Video Access Code'
                  name='video_access_code'
                >
                  <Input
                    placeholder='Video Access Code'
                    name='video_access_code'
                  ></Input>
                </Form.Item> */}
					</Col>
					<Col span={24}>
						<Form.Item
							label='Game Ruleset'
							name='rulesetISbb_rpg_rulesetsID'
							style={forItemStyle}
							rules={[{ required: true, message: 'Please select game ruleset!' }]}
						>
							<Select>
								{rulesets.map((ruleset) => (
									<Select.Option value={ruleset._id}>{ruleset.name}</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row justify='end' className='my-5'>
					<Form.Item>
						<Button type='primary' htmlType='submit' loading={creating}>
							Start Game
						</Button>
					</Form.Item>
				</Row>
			</Form>
		</>
	)
}

export default NewGameModal
