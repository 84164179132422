import React from 'react'
import SingleCharacter from './SingleCharacter'
import { charaterColors } from './../../utils/common'

const DmCharactersView = ({ dataGame, ui_images, secret_roll, setIsDmModelVisible }) => {
	return (
		<div className='mb-2 characters-list-wrapper'>
			<div className='d-flex align-stretch'>
				<SingleCharacter
					dataGame={dataGame}
					type='dm_v1'
					playerBoxImg={ui_images.dm_box_image}
					character={dataGame.player}
					dmImage={ui_images.dm_image}
					secret_roll={secret_roll}
					setIsDmModelVisible={setIsDmModelVisible}
				/>
				<div className='players-width d-flex flex-wrap dm'>
					{dataGame.players.length &&
						dataGame.players.map((item, index) => {
							if (item.playerNUM == dataGame.player.playerNUM) {
								return null
							} else {
								return (
									<SingleCharacter
									    key={item.playerNUM}
										dataGame={dataGame}
										type='oponent'
										playerBoxImg={ui_images.player_box_image}
										character={item}
										characterColor={charaterColors[index]}
										secret_roll={secret_roll}
									/>
								)
							}
						})}
				</div>
			</div>
		</div>
	)
}

export default DmCharactersView
