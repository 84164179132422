import { Button, Input, Tooltip } from 'antd'
import React, { useRef, useState, useEffect, Fragment } from 'react'
import {
	EditOutlined,
	DeleteOutlined,
	PlusOutlined,
	CheckOutlined,
	CloseOutlined,
	SaveOutlined,
} from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid';

const SaveBtn = (
	<Tooltip title='Save'>
		<Button size='small' icon={<SaveOutlined />} style={{ position: 'absolute', right: 5, bottom: 5 }} />
	</Tooltip>
)

const NotesInput = ({ addNote, data, updateNote, deleteNote, listOnly }) => {
	const [editable, setEditable] = useState(false)
	const [noteInput, setNoteInput] = useState('')
	const [id, setId] = useState('')
	const viewHelper = []
	
	if (listOnly) {
		return (
			<div className='d-flex mb-4 align-center' key={uuidv4()}>
				<div className='flex-grow-1'>
					<Input.TextArea autoSize={{ minRows: 2 }} value={data.note_text} style={{ pointerEvents: 'none' }} />
				</div>
			</div>
		)
	}
	if (data) {
		if (editable) {
			viewHelper.push(
				<div className='d-flex mb-4 align-center' style={{ position: 'relative' }} key={uuidv4()}>
					<div className='flex-grow-1'>
						<Input.TextArea
							autoFocus
							autoSize={{ minRows: 2 }}
							value={noteInput}
							onChange={(e) => setNoteInput(e.target.value)}
							placeholder='Start Updating notes...'
							onFocus={function (e) {
								var val = e.target.value
								e.target.value = ''
								e.target.value = val
							}}
						/>
					</div>
					<div style={{ position: 'absolute', bottom: 0, right: 0 }}>
						<Button
							disabled={!noteInput}
							type='link'
							size='small'
							onClick={() => {
								updateNote(noteInput, id)
								setEditable(false)
							}}
						>
							<CheckOutlined />
						</Button>
						<Button
							type='link'
							size='small'
							onClick={() => {
								setEditable(false)
							}}
						>
							<CloseOutlined />
						</Button>
					</div>
				</div>
			)
		} else {
			viewHelper.push(
				<div className='d-flex mb-4 align-center' style={{ position: 'relative' }} key={uuidv4()}>
					<div className='flex-grow-1'>
						<Input.TextArea autoSize={{ minRows: 2 }} value={data.note_text} style={{ pointerEvents: 'none' }} />
					</div>

					<div className='ml-2' style={{ position: 'absolute', bottom: 0, right: 0 }}>
						<Button
							type='link'
							size='small'
							onClick={() => {
								setEditable(true)
								setNoteInput(data.note_text)
								setId(data.note_id)
							}}
						>
							<EditOutlined />
						</Button>
						<Button
							type='link'
							size='small'
							danger
							onClick={() => {
								deleteNote(data.note_id)
							}}
						>
							<DeleteOutlined />
						</Button>
					</div>
				</div>
			)
		}
	} else {
		viewHelper.push(
			<div className='d-flex mb-4 align-center' key={uuidv4()}>
				<div className='flex-grow-1' style={{ position: 'relative' }}>
					<Input.TextArea
						autoSize={{ minRows: 2 }}
						value={noteInput}
						onChange={(e) => setNoteInput(e.target.value)}
						onBlur={() => {
							if (!noteInput) return
							addNote(noteInput)
							setNoteInput('')
						}}
						placeholder='Start typing notes...'
					/>
					{SaveBtn}
				</div>
				{/* <div className='ml-2'>
					<Button
						disabled={!noteInput}
						onClick={() => {
							addNote(noteInput)
							setNoteInput('')
						}}
					>
						<PlusOutlined span={true} />
					</Button>
				</div> */}
			</div>
		)
	}
	return <>{viewHelper}</>
}

export default NotesInput
