import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const HogItem = ({ handleMouseDownHog, hogItem, isDragging, isDraw }) => {
	const [hogPositionState, setHogPositionState] = useState({ x: 500, y: 500 })

	const { data } = useSelector((state) => state.appSettings)
	const { player, players } = useSelector((state) => state.gameData.data)
	const { ui_images } = data

	useEffect(() => {
		if (!isDragging) {
			setHogPositionState({
				x: hogItem.posX,
				y: hogItem.posY,
			})
		}
	}, [hogItem])

	useEffect(() => {
		setHogPositionState({
			x: hogItem.posX,
			y: hogItem.posY,
		})
	}, [hogItem])

	const dynamicHandling = () => {
		if (player.playerNUM === 99) {
			handleMouseDownHog(hogItem)
		}
	}

	return (
		<div
			style={{
				top: hogPositionState.y,
				left: hogPositionState.x,
				zIndex: 10,
				pointerEvents: !isDraw ? 'none' : 'all',
			}}
			onMouseDown={dynamicHandling}
			onMouseMove={(e) => e.stopPropagation()}
			className={`player-element ${player.playerNUM === 99 ? 'dm' : ''}`}
		>
			{ui_images && <img src={ui_images.hog_image} width={36} alt='' draggable={false} />}
		</div>
	)
}

export default HogItem
