import React from 'react'

const PluginsTab = () => {
	return (
		<div className='scrollable-one'>
			<div>
				<h3>Coming soon</h3>
			</div>
		</div>
	)
}

export default PluginsTab
