import {
  CALL_GAME_DATA,
  REMOVE_GAME_DATA,
  SET_GAME_DATA,
} from "../../constants";

export const setGameDataAction = (data) => {
  return {
    type: SET_GAME_DATA,
    payload: data,
  };
};

export const callGameDataAction = () => {
  return {
    type: CALL_GAME_DATA,
  };
};

export const removeGameDataAction = () => {
  return {
    type: REMOVE_GAME_DATA,
  };
};
