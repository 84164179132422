import React from 'react'
import { Tooltip } from 'antd'
import { getContainer } from '../../utils/helpers'

const PlayerPopup = ({ character, playerBoxImg, top, left }) => {
	return (
		<div style={{ widht: '100px', top: top, left: left }}>
			<div className='player-container'>
				<div className='player background-settings-v2' style={{ backgroundImage: `url(${character?.imageISfile})` }} />
				<div className='player-box'>
					<img width={100} height={127} src={playerBoxImg} />
				</div>
				<h4 className='character-name w-100 player-text white mb-0'>{character?.character_name}</h4>
				<h4 className='player-name w-100 player-text white mb-0'>{character?.player_name}</h4>
				<h4 className='player-stats-hp player-text white mb-0'>
					<Tooltip title='HP Current' getPopupContainer={getContainer}>
						{character?.hp}
					</Tooltip>
				</h4>
				<h4 className='player-stats-ac player-text white mb-0'>
					<Tooltip title='Armour Class' placement='left' getPopupContainer={getContainer}>
						{character?.ac}
					</Tooltip>
				</h4>
				<h4 style={{ backgroundColor: character?.color }} className={'player-stats-num player-text white mb-0'}>
					{character?.playerNUM}
				</h4>
			</div>
		</div>
	)
}

export default PlayerPopup
