import React from 'react'
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { callToApi } from './../../utils/index'
import { useSelector } from 'react-redux'

const overlayCanvasStyle = {
	position: 'absolute',
	left: '50px',
	top: '66px',
	zIndex: 1,
	opacity: 0.6,
}
const scene_id_key = '__scene_id'

const OverlayCanvas = ({ overlayCanvasMouseAction, func1, setIsDraw }, ref) => {
	const gameData = useSelector((state) => state.gameData.data)
	const { scene } = gameData || {}
	const { _id: id, overlayISfile } = scene || {}

	React.useEffect(() => {
		localStorage.setItem(scene_id_key, id)
	}, [id])

	const overlayCanvasRef = useRef(null)
	const [obscureInProgress, setObscureInProgress] = useState(false)

	const handleCanvasMouseDown = () => {
		if (overlayCanvasMouseAction) {
			let canvas = overlayCanvasRef.current
			let ctx = canvas.getContext('2d')
			ctx.strokeStyle = 'rgba(0,0,0,1)'
			ctx.lineWidth = 30
			ctx.beginPath()
			ctx.lineCap = 'round'
			ctx.lineJoin = 'round'
			ctx.stroke()
			setObscureInProgress(true)
		}
	}

	const handleCanvasMouseMove = ({ nativeEvent }) => {
		if (obscureInProgress) {
			if (overlayCanvasMouseAction) {
				const { offsetX, offsetY } = nativeEvent
				let canvas = overlayCanvasRef.current
				let ctx = canvas.getContext('2d')
				if (overlayCanvasMouseAction === 'erase') {
					ctx.globalCompositeOperation = 'destination-out'
				}
				if (overlayCanvasMouseAction === 'draw') {
					ctx.globalCompositeOperation = 'destination-over'
				}
				ctx.strokeStyle = 'rgba(0,0,0,1)'
				ctx.lineJoin = 'round'
				ctx.lineCap = 'round'
				ctx.lineWidth = 30
				ctx.stroke()
				ctx.lineTo(offsetX, offsetY)
				setIsDraw(false)
			}
		}
	}

	const updateCanvas = async (v, postData = {}) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'update_scene',
			player_id: playerId,
			scene_id: localStorage.getItem(scene_id_key),
			field: 'overlayISfile',
			value: v,
			...postData,
		}
		console.log('Post Body for Update Canvas:', body)
		try {
			const { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
		}
	}

	const handleCanvasMouseUp = () => {
		if (overlayCanvasMouseAction) {
			let canvas = overlayCanvasRef.current
			let ctx = canvas.getContext('2d')
			ctx.closePath()
			setObscureInProgress(false)
			updateCanvas(canvas.toDataURL())
			setIsDraw(true)
		}
	}

	useImperativeHandle(
		ref,
		() => ({
			fillCanvas() {
				let canvas = overlayCanvasRef.current
				let ctx = canvas.getContext('2d')
				ctx.fillStyle = 'rgba(0,0,0,1)'
				ctx.globalCompositeOperation = 'destination-over'
				ctx.fillRect(0, 0, canvas.width, canvas.height)
				updateCanvas(canvas.toDataURL(), { field: 'overlayYN', value: 1 })
			},
			clearCanvas() {
				let canvas = overlayCanvasRef.current
				let ctx = canvas.getContext('2d')
				ctx.fillStyle = 'rgb(0,0,0)'
				ctx.globalCompositeOperation = 'destination-out'
				ctx.fillRect(0, 0, canvas.width, canvas.height)
				updateCanvas(canvas.toDataURL(), { field: 'overlayYN', value: 0 })
			},
		}),
		[]
	)

	useEffect(() => {
		let canvas = overlayCanvasRef.current
		canvas.width = 722
		canvas.height = 712
		let ctx = canvas.getContext('2d')
		let image = new Image()
		image.crossOrigin = '*'
		image.src = overlayISfile
		image.onload = () => {
			ctx.drawImage(image, 0, 0, 722, 712)
		}
	}, [id])

	return (
		<canvas
			onMouseDown={handleCanvasMouseDown}
			onMouseMove={handleCanvasMouseMove}
			onMouseUp={handleCanvasMouseUp}
			style={{ ...overlayCanvasStyle }}
			ref={overlayCanvasRef}
		/>
	)
}

export default forwardRef(OverlayCanvas)
