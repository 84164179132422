import React from 'react'
import { Avatar, Row } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'

const SingleMessage = ({ msg, toPlayerNum }) => {
	const { data } = useSelector((state) => state.gameData)
	const { data: dmData } = useSelector((state) => state.appSettings)

	let avatarLink
	if (msg.sender_playerNUM === 99) {
		avatarLink = dmData.ui_images.dm_image
	} else {
		avatarLink = data.players[msg.sender_playerNUM - 1].imageISfile
	}

	let myAvatarLink
	if (data.player.playerNUM === 99) {
		myAvatarLink = dmData.ui_images.dm_image
	} else {
		myAvatarLink = data.players[data.player.playerNUM - 1].imageISfile
	}

	return (
		<>
			{msg.from_me ? (
				<Row className='mb-4 d-flex justify-end' align='bottom'>
					<div>
						<div
							style={{
								backgroundColor: '#2787F5',
								color: 'white',
								padding: '8px 16px',
								borderRadius: '7px',
							}}
						>
							{msg.message}
						</div>
						<span className='chat-timestamp' style={{ float: 'right' }}>
							{moment(msg.time).format('hh:mm a')}
						</span>
					</div>
					<div className='ml-4'>
						<Avatar size='large' src={myAvatarLink} />
					</div>
				</Row>
			) : (
				<Row className='mb-4 d-flex' align='bottom'>
					<div className='mr-4'>
						<Avatar size='large' src={avatarLink} />
					</div>

					<div>
						<div
							style={{
								backgroundColor: '#d0e0e14f',
								color: 'black',
								padding: '8px 16px',
								borderRadius: '7px',
							}}
						>
							{msg.message}
						</div>
						<span className='chat-timestamp' style={{ float: 'left' }}>
							{moment(msg.time).format('hh:mm a')}
						</span>
					</div>
				</Row>
			)}
		</>
	)
}

export default SingleMessage
