import { Tooltip } from 'antd'
import React from 'react'
import { getContainer } from '../../utils/helpers'

const ElementPopup = ({ elementObj, playerBoxImg, ui_images }) => {
	return (
		<div style={{ widht: '200px' }}>
			<div className='player-container'>
				<div
					className='player background-settings-v2'
					style={{
						backgroundImage: `url(${elementObj.imageISfile || ui_images.generic_monster})`,
					}}
				/>
				<div className='player-box'>
					<img width={100} height={127} src={playerBoxImg} />
				</div>
				<h4 className='my-character-name w-100 player-text white mb-0'>{elementObj.name}</h4>
				<h4 className='player-name w-100 player-text white mb-0'>{elementObj.name}</h4>
				<h4 className='player-stats-hp player-text white mb-0'>
					<Tooltip title='HP Current' getPopupContainer={getContainer}>
						{elementObj.hp}
					</Tooltip>
				</h4>
				<h4 className='player-stats-ac player-text white mb-0'>
					<Tooltip title='Armour Class' placement='left' getPopupContainer={getContainer}>
						{elementObj.ac}
					</Tooltip>
				</h4>
				<h4
					//   style={{ backgroundColor: character.color }}
					className={'player-stats-num player-text white mb-0'}
				>
					{elementObj.elementNUM}
				</h4>
			</div>
		</div>
	)
}

export default ElementPopup
