import React from "react";
import { useSelector } from "react-redux";
import JitsiMeeting from "./JitsiMeeting";

const index = () => {
  const { game } = useSelector((state) => state.appSettings.data);

  return (
    <div>
      {game.video_service == null && <h2>Video not available from API</h2>}
      {game.video_service.toLowerCase() === "jitsi".toLowerCase() ? (
        <JitsiMeeting />
      ) : (
        <div style={{ padding: "35px", background: "#b1adad" }}>
          <h1>
            Sorry, looks like you are using our integrated video! If the DM
            chooses to use Jitsu in the Game Settings you can access your video
            here
          </h1>
        </div>
      )}
    </div>
  );
};

export default index;
