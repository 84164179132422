import { Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GameFunctions from '../components/GameFunctions'
import GameMap from '../components/GameMap'
import LoginSignUpModal from './../components/LoginSignupModal'
import { callLoadGame } from './../utils/index'
import { setGameDataAction } from '../redux/actions/gameData'
import LoginForm from './../components/Forms/LoginForm'
import SignupForm from './../components/Forms/SignupForm'
import ResetPasswordForm from './../components/Forms/ResetPasswordForm'
import NoAccessIdLoginForm from '../components/Forms/NoAccessIdLoginForm'
import { getGameAndAccessId } from '../utils/helpers'
import NoAccessIdSignupForm from '../components/Forms/NoAccessIdSignupForm'
import NoAccessIdResetPasswordForm from '../components/Forms/NoAccessIdResetPasswordForm'
import GameListModal from './GameListModal'
import keys from '../config/keys'

const View = () => {
	const [characterName, setCharacterName] = useState('')
	const [characterImage, setCharacterImage] = useState('')
	const [playerNum, setPlayerNum] = useState('')
	const dispatch = useDispatch()
	const { accessId } = getGameAndAccessId()
	const [noAccessIdLoginModal, setNoAccessIdLoginModal] = useState(accessId === '-1')
	const [noAccessIdSignupModal, setNoAccessIdSignupModal] = useState(false)
	const [noAccessIdForgotPassModal, setNoAccessIdForgotPassModal] = useState(false)
	const [gameListModal, setGameListModal] = useState(null)
	const [signupSuccessful, setSignupSuccessful] = useState(false)

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()

			if (data.login_required !== undefined) {
				if (data.login_required === 1) {
					setIsLoginModalVisible(true)

					setCharacterName(data.character_name)
					setCharacterImage(data.character_image)
					setPlayerNum(data.playerNUM)
				}
			} else {
				dispatch(setGameDataAction(data))
			}
		} catch (err) {
			console.log(err)
		}
	}

	const [isLoginModalVisible, setIsLoginModalVisible] = useState(false)
	const [isSignupModalVisible, setIsSignupModalVisible] = useState(false)
	const [isResetPassModalVisible, setIsResetPassModalVisible] = useState(false)
	const { data } = useSelector((state) => state.appSettings)
	const { data: dataGame } = useSelector((state) => state.gameData)
	const { ui_images, dice, character_sheet } = data

	useEffect(() => {
		accessId !== '-1' && loadGame()
	}, [])

	return (
		<>
			<div
				style={{
					backgroundImage: `url(${ui_images?.background_image})`,
					backgroundSize: '100% 100%',
					minHeight: '100vh',
				}}
				className='container-custom background-settings'
			>
				<div className='d-flex custom-flex px-3 justify-center'>
					{dataGame ? <GameMap dataGame={dataGame} ui_images={ui_images} dice={dice} /> : null}

					<GameFunctions ui_images={ui_images} dataGame={dataGame} character_sheet={character_sheet} />
				</div>
			</div>

			<LoginSignUpModal
				handleCancel={() => {
					setIsResetPassModalVisible(false)
					setIsLoginModalVisible(true)
				}}
				isModalVisible={isResetPassModalVisible}
				title=''
				wrapClassName='no-modal-close-btn'
				footer={
					<div className='d-flex align-center ml-3'>
						<p className='mb-0 mr-4'> No Account Yet? </p>
						<Button
							type='primary'
							onClick={() => {
								setIsResetPassModalVisible(false)
								setIsSignupModalVisible(true)
							}}
						>
							Sign Up
						</Button>
					</div>
				}
			>
				<ResetPasswordForm
					resetModal={() => setIsResetPassModalVisible(false)}
					loginModal={() => setIsLoginModalVisible(true)}
					handleCancel={() => {
						setIsResetPassModalVisible(false)
						setIsLoginModalVisible(true)
					}}
				/>
			</LoginSignUpModal>

			<LoginSignUpModal
				handleCancel={() => {
					setIsLoginModalVisible(false)
					setSignupSuccessful(false)
				}}
				isModalVisible={isLoginModalVisible}
				title={null}
				wrapClassName='no-modal-close-btn'
				maskClosable={false}
				footer={
					<div className='d-flex align-center'>
						<p className='mb-0 mr-4 ml-2'> No Account Yet? </p>
						<Button
							type='primary'
							onClick={() => {
								setIsLoginModalVisible(false)
								setIsSignupModalVisible(true)
							}}
						>
							Sign up
						</Button>
					</div>
				}
			>
				<LoginForm
					ok={() => {
						setIsLoginModalVisible(false)
					}}
					signupSuccessful={signupSuccessful}
					resetModal={() => setIsResetPassModalVisible(true)}
					loadGame={loadGame}
					dmImage={ui_images?.dm_image}
					characterName={characterName}
					characterImage={characterImage}
					playerNum={playerNum}
				/>
			</LoginSignUpModal>

			<LoginSignUpModal
				handleCancel={() => {
					setIsSignupModalVisible(false)
					setIsLoginModalVisible(true)
				}}
				isModalVisible={isSignupModalVisible}
				title=''
				wrapClassName='no-modal-close-btn'
				footer={
					<div className='ml-2 d-flex align-center'>
						<p className='mb-0 mr-1'>By clicking Sign Up, you are accepting our </p>
						<a>Terms and Conditions</a>
					</div>
				}
			>
				<SignupForm
					ok={(success = false) => {
						success && setSignupSuccessful(true)
						setIsSignupModalVisible(false)
					}}
					siginModal={() => {
						setIsLoginModalVisible(true)
					}}
				/>
			</LoginSignUpModal>

			{/* No Access ID Modals */}
			<LoginSignUpModal
				handleCancel={() => {
					setIsLoginModalVisible(false)
					setSignupSuccessful(false)
				}}
				isModalVisible={noAccessIdLoginModal}
				title={null}
				wrapClassName='no-modal-close-btn'
				maskClosable={false}
				footer={
					<div className='d-flex align-center'>
						<p className='mb-0 mr-4 ml-2'> No Account Yet? </p>
						<Button
							type='primary'
							onClick={() => {
								setNoAccessIdLoginModal(false)
								setNoAccessIdSignupModal(true)
							}}
						>
							Sign up
						</Button>
					</div>
				}
			>
				<NoAccessIdLoginForm
					ok={(data) => {
						setGameListModal(data)
					}}
					signupSuccessful={signupSuccessful}
					resetModal={() => setNoAccessIdForgotPassModal(true)}
				/>
			</LoginSignUpModal>

			<LoginSignUpModal
				handleCancel={() => {
					setNoAccessIdSignupModal(false)
					setNoAccessIdLoginModal(true)
				}}
				isModalVisible={noAccessIdSignupModal}
				title=''
				wrapClassName='no-modal-close-btn'
				footer={
					<div className='ml-2 d-flex align-center'>
						<p className='mb-0 mr-1'>By clicking Sign Up, you are accepting our </p>
						<a>Terms and Conditions</a>
					</div>
				}
			>
				<NoAccessIdSignupForm
					ok={(success = false) => {
						success && setSignupSuccessful(true)
						setNoAccessIdSignupModal(false)
					}}
					siginModal={() => {
						setNoAccessIdLoginModal(true)
					}}
				/>
			</LoginSignUpModal>

			<LoginSignUpModal
				handleCancel={() => {
					setNoAccessIdForgotPassModal(false)
					setNoAccessIdLoginModal(true)
				}}
				isModalVisible={noAccessIdForgotPassModal}
				title=''
				wrapClassName='no-modal-close-btn'
				footer={
					<div className='d-flex align-center ml-3'>
						<p className='mb-0 mr-4'> No Account Yet? </p>
						<Button
							type='primary'
							onClick={() => {
								setNoAccessIdForgotPassModal(false)
								setNoAccessIdSignupModal(true)
							}}
						>
							Sign Up
						</Button>
					</div>
				}
			>
				<NoAccessIdResetPasswordForm
					resetModal={() => setNoAccessIdForgotPassModal(false)}
					loginModal={() => setNoAccessIdLoginModal(true)}
					handleCancel={() => {
						setNoAccessIdForgotPassModal(false)
						setNoAccessIdLoginModal(true)
					}}
				/>
			</LoginSignUpModal>

			<LoginSignUpModal
				className='ant-modal-width-mid'
				handleCancel={() => setGameListModal(null)}
				isModalVisible={!!gameListModal}
				title={null}
				wrapClassName='no-modal-close-btn'
				maskClosable={false}
				footer={null}
				bodyStyle={{ height: '550px', overflowY: 'scroll', paddingBottom: 24 }}
			>
				<GameListModal
					loggedInData={gameListModal}
					logOff={() => {
						localStorage.removeItem(keys.sessionId)
						setGameListModal(null)
						setNoAccessIdLoginModal(true)
					}}
				/>
			</LoginSignUpModal>
			{/* End No Access ID Modals */}
		</>
	)
}

export default View
