import React, { useState } from 'react'
import { useEffect } from 'react'
import { callLoadGame } from '../../utils'
import DmMessageView from './DmMessageView'
import PlayerMessageView from './PlayerMessageView'
import { callChatListAction, setChatListAction } from './../../redux/actions/chatList/index'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import { callToApi } from './../../utils/index'
import { setGameDataAction } from './../../redux/actions/gameData/index'

const MessengerPopup = ({ dataGame, loadGame }) => {
	const { chatListData, chatListLoading } = useSelector((state) => state.chatList)
	const dispatch = useDispatch()

	const loadChat = async () => {
		dispatch(callChatListAction())
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_chats',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			const { chats } = data
			if (chats === null) {
				dispatch(setChatListAction({}))
			} else {
				dispatch(setChatListAction(chats))
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		loadChat()
	}, [])

	if (chatListLoading) {
		return (
			<div className='text-center mt-4'>
				<Spin />
			</div>
		)
	}

	const initialActiveTabId = localStorage.getItem('ActiveMessengerTabId')

	if (chatListData) {
		return (
			<>
				{dataGame ? (
					<>
						{dataGame.player.playerNUM === 99 ? (
							<DmMessageView initialActiveTabId={initialActiveTabId} loadGame={loadGame} dataGame={dataGame} />
						) : (
							<PlayerMessageView initialActiveTabId={initialActiveTabId} loadGame={loadGame} dataGame={dataGame} />
						)}
					</>
				) : null}
			</>
		)
	}
	return <h2>Messenger</h2>
}

export default MessengerPopup
