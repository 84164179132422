import React, { useState, useEffect } from "react";
import "./styles.css";
import PlayerItem from "./PlayerItem";
import { callToApi } from "./../../utils/index";
import ElementItem from "./ElementItem";
import HogItem from "./HogItem";

const PlayGround = ({
  playCanvasZIndex,
  dataGame,
  handleMouseDown,
  handleMouseDownElement,
  handleMouseDownHog,
  dPosition,
  ePosition,
  isDragging,
  setIsDragging,
  playersList,
  elementList,
  hogItem,
  isDraw,
}) => {
  const { positions, players, elements } = dataGame;
  const [selectedCircle, setSelectedCircle] = useState("");

  return (
    <>
      {/* <div style={{ zIndex: playCanvasZIndex }} className="play-container"> */}
      {/* Implement this event on game map container */}
      {/* <div className="play-area"> */}
      {playersList.map((value) => (
        <PlayerItem
          dPosition={dPosition}
          isDragging={isDragging}
          key={value.color}
          playerItemData={value}
          handleMouseDown={handleMouseDown}
          dataGame={dataGame}
          isDraw={isDraw}
        />
      ))}

      {elementList.map((value) => (
        <ElementItem
          ePosition={ePosition}
          isDragging={isDragging}
          key={value.name}
          elementItemData={value}
          handleMouseDownElement={handleMouseDownElement}
          dataGame={dataGame}
          isDraw={isDraw}
        />
      ))}
      {
        <HogItem
          hogItem={hogItem}
          isDragging={isDragging}
          handleMouseDownHog={handleMouseDownHog}
          isDraw={isDraw}
        />
      }
    </>
  );
};

export default PlayGround;
