import React, { useEffect, useState } from 'react'
import { callToApi } from '../../utils'
import { Spin } from 'antd'
import NotesInput from './NotesInput'
import { v4 as uuidv4 } from 'uuid';

const Notes = ({ playerNum, noteType, scene_id, listOnly }) => {
	const [notesList, setNotesList] = useState([])
	const [loading, setloading] = useState(true)

	const listNotes = async () => {
		setloading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_notes',
			playerNUM: playerNum,
			type: noteType,
			player_id: playerId,
			scene_id: scene_id,
		}
		try {
			const { data } = await callToApi({ body })
			const notes = data.notes.filter((x) => x.note_text && !x.note_file)
			setNotesList(notes)
			setloading(false)
		} catch (err) {
			console.log(err)
		}
	}

	const addNote = async (val) => {
		if (val.length) {
			setloading(true)
			let queryP = new URLSearchParams(window.location.search)
			let playerId = queryP.get('access')
			const body = {
				api_method: 'add_note',
				type: noteType,
				player_id: playerId,
				playerNUM: playerNum,
				note_text: val,
				scene_id: scene_id,
			}
			try {
				const { data } = await callToApi({ body })
				listNotes()
			} catch (err) {
				console.log(err)
			}
		}
	}

	const updateNote = async (val, id) => {
		setloading(true)
		if (val.length) {
			let queryP = new URLSearchParams(window.location.search)
			let playerId = queryP.get('access')
			const body = {
				api_method: 'update_note',
				playerNUM: playerNum,
				type: noteType,
				player_id: playerId,
				note_text: val,
				note_id: id,
				scene_id,
				scene_id,
			}
			try {
				const { data } = await callToApi({ body })
				listNotes()
			} catch (err) {
				console.log(err)
			}
		}
	}

	const deleteNote = async (id) => {
		setloading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'delete_note',
			playerNUM: playerNum,
			type: noteType,
			player_id: playerId,
			note_id: id,
			scene_id: scene_id,
		}
		try {
			const { data } = await callToApi({ body })
			listNotes()
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		listNotes()
	}, [])
	return (
		<>
			{!listOnly && <NotesInput addNote={addNote} />}
			{!loading && notesList ? (
				notesList.map((item) => (
					<NotesInput listOnly={listOnly} updateNote={updateNote} deleteNote={deleteNote} key={item.note_id || uuidv4()} data={item} />
				))
			) : (
				<div className='text-center my-3'>
					<Spin className='mr-2' />
					Loading Notes...
				</div>
			)}
		</>
	)
}

export default Notes
