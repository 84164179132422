import React, { useState } from 'react'
import { Form, Input, Button, message, Row } from 'antd'
import { callToApi } from '../../utils'

const ResetPasswordForm = ({ resetModal, loginModal, handleCancel }) => {
	const [passwordReset, setPasswordReset] = useState('')
	const onFinish = async (values) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			player_id: playerId,
			api_method: 'password_reset',
			email: values.email,
		}
		try {
			const { data } = await callToApi({ body })
			console.log(data)
			// message.success(data.message)
			resetModal()
			loginModal()
		} catch (err) {
			setPasswordReset('No user found for that email')
			console.log(err)
		}
	}

	const onFinishFailed = (errorInfo) => {
		message.error('Signup Failed, Try again')
		console.log('Failed:', errorInfo)
	}
	return (
		<div>
			<div style={{ marginBottom: 25 }}>
				<h1 className='mb-0'>Password Reset</h1>
				<span>Enter your email to reset your password.</span>
			</div>
			<Form layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item
					label='Email Address'
					required={false}
					name='email'
					rules={[{ required: true, message: 'Please input your email!' }]}
				>
					<Input placeholder='Email address' />
				</Form.Item>

				<Row justify='space-between' style={{ marginTop: 20 }}>
					<Button type='link' style={{ padding: 0, color: '#fff' }} onClick={handleCancel}>
						Remember your password? <strong className='ml-1'>Sign in</strong>
					</Button>
					<Button type='primary' htmlType='submit'>
						Reset Password
					</Button>
					{/* <Button
						type='link'
						onClick={() => {
							resetModal()
							loginModal()
						}}
					>
						Return to Sign in page?
					</Button> */}
				</Row>
				{passwordReset && (
					<div className='d-flex'>
						<h3>{passwordReset}</h3>
					</div>
				)}
			</Form>
		</div>
	)
}

export default ResetPasswordForm
