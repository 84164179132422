import { Modal, Row, Col } from 'antd'
import React from 'react'
import Draggable from 'react-draggable'
import { DragOutlined } from '@ant-design/icons'

const PrimaryModel = ({
	bodyStyle = {},
	isDraggable = true,
	isModalVisible,
	children,
	title,
	handleCancel,
	afterClose,
}) => {
	const draggleRef = React.createRef()
	const [disabled, setDisabled] = React.useState(true)
	const [bounds, setBounds] = React.useState({ left: 0, top: 0, bottom: 0, right: 0 })

	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window.document.documentElement
		const targetRect = draggleRef.current?.getBoundingClientRect()
		if (!targetRect) return

		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		})
	}

	return (
		<>
			<Modal
				centered
				destroyOnClose
				onCancel={handleCancel}
				footer={null}
				wrapClassName='primary-modal'
				title={
					<Row justify='space-between' align='middle' wrap={false} gutter={[10, 0]} style={{ marginRight: 25 }}>
						<Col>{title}</Col>
						<Col>
							{isDraggable && (
								<div
									className='grabbable'
									onMouseOver={() => {
										if (!isDraggable) return
										if (disabled) {
											setDisabled(false)
										}
									}}
									onMouseOut={() => {
										if (!isDraggable) return
										setDisabled(true)
									}}
								>
									<DragOutlined />
								</div>
							)}
						</Col>
					</Row>
				}
				closable={true}
				visible={isModalVisible}
				width={768}
				afterClose={afterClose}
				bodyStyle={bodyStyle}
				mask={!isDraggable}
				maskClosable={!isDraggable}
				modalRender={(modal) => (
					<Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
						<div className='container' ref={draggleRef}>
							{modal}
						</div>
					</Draggable>
				)}
			>
				{children}
			</Modal>
		</>
	)
}

export default PrimaryModel
