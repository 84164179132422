import React, { useState } from 'react'
import PrimaryModel from './../PrimaryModel/index'
import { useSelector } from 'react-redux'
import CharacterPopup from './../CharacterPopup'
import { Popover, Button, Image, Switch, Tooltip } from 'antd'
import { useEffect } from 'react'
import { BsChatDots } from 'react-icons/bs'
import { getContainer } from '../../utils/helpers'
import { v4 as uuidv4 } from 'uuid';

const firstFont = { fontSize: 20, marginBottom: 7 }

const SingleCharacter = ({ character, playerBoxImg, type, dmImage, dataGame, setIsDmModelVisible, secret_roll }) => {
	const [popoverVisibility, setPopoverVisibility] = useState(false)
	const [isCharacterViewVisible, setIsCharacterViewVisible] = useState(false)
	const [characterImageGlow, setCharacterImageGlow] = useState(false)
	const [dmScretRollGlow, setDmScretRollGlow] = useState(false)
	const [popoverState, setPopoverState] = useState([])

	const { appSettings, secretRoll } = useSelector((state) => state)
	const getAlerts = (id) => {
		const _alerts = dataGame?.chat_alerts?.[id] || []
		return _alerts.filter((item) => !item.seen)
	}
	const unSeenAlerts = getAlerts(character.playerNUM)
	const unSeenAlertsCount = unSeenAlerts.length

	const alertEl = unSeenAlertsCount ? (
		<div
			onClick={(e) => {
				e.stopPropagation()
				localStorage.setItem('ActiveMessengerTabId', character.playerNUM)
				document.getElementById('messenger-open-button')?.click()
			}}
			style={{ cursor: 'pointer', position: 'absolute', top: -26, right: 26 }}
		>
			<div className='blink_me'>
				<BsChatDots size={25} color='red' />
			</div>
		</div>
	) : null

	const { data } = appSettings
	const { popoverRedux } = useSelector((state) => state)
	const { dice_rolls } = dataGame

	const handlePopoverVisibleChange = (visible) => {
		setPopoverVisibility(visible)
	}

	useEffect(() => {
		setDmScretRollGlow(secretRoll.dmGlow)
	}, [secretRoll.dmGlow])

	useEffect(() => {
		if (popoverRedux[character.playerNUM] !== undefined) {
			if (popoverRedux[character.playerNUM] === false) {
				setPopoverVisibility(false)
				setCharacterImageGlow(false)
			} else {
				setCharacterImageGlow(true)
				setPopoverState(
					dice_rolls[character.playerNUM] === undefined
						? [<p key={uuidv4()}>No dice rolls yet</p>]
						: [
								...dice_rolls[character.playerNUM].map((i, index) => {
									return (
										<div key={uuidv4()} style={index === 0 ? firstFont : {}}>
											{dice[i.dice_code - 1] && dice[i.dice_code - 1].hasOwnProperty('dice_sides_images') ? (
												<Image
													width={18}
													preview={false}
													src={dice[i.dice_code - 1].dice_sides_images[i.dice_result_value]}
													alt={i.dice_result_value}
												/>
											) : (
												i.dice_result
											)}
										</div>
									)
								}),
						  ]
				)

				setPopoverVisibility(true)
			}
		}
	}, [popoverRedux])

	let isDm = false
	if (dataGame.player.playerNUM === 99) {
		isDm = true
	}
	const { character_sheet, dice } = data

	const checkDiceHistory = () => {
		setPopoverState(
			dice_rolls?.[character.playerNUM] === undefined
				? [<p key={uuidv4()}>No dice rolls yet</p>]
				: [
						...dice_rolls[character.playerNUM].map((i, index) => {
							return (
								<div key={uuidv4()} style={index === 0 ? firstFont : {}}>
									{dice[i.dice_code - 1] && dice[i.dice_code - 1].hasOwnProperty('dice_sides_images') ? (
										<Image
											width={18}
											preview={false}
											src={dice[i.dice_code - 1].dice_sides_images[i.dice_result_value]}
											alt={i.dice_result_value}
										/>
									) : (
										i.dice_result
									)}
								</div>
							)
						}),
				  ]
		)
	}

	const handlePlayerMouseEnter = () => {
		checkDiceHistory()
		setPopoverVisibility(true)
	}

	const content = <div>{popoverState.length ? popoverState : <p>Loading...</p>}</div>

	const viewHelper = []
	if (type === 'player') {
		viewHelper.push(
			<Popover
				onVisibleChange={handlePopoverVisibleChange}
				visible={popoverVisibility}
				placement='leftTop'
				content={content}
				title='Dice History'
				trigger='hover'
				key={type}
			>
				<div className='my-player-container' onClick={() => setIsCharacterViewVisible(true)}>
					{alertEl}
					<div
						className={
							characterImageGlow
								? 'my-player background-settings-v2 main-character-image-glow'
								: 'my-player background-settings-v2'
						}
						style={{ backgroundImage: `url(${character.imageISfile})` }}
					/>
					<div className='my-player-box'>
						<img width={250} height={317.5} src={playerBoxImg} />
					</div>
					<h4 className='my-character-name w-100 my-player-text white mb-0'>{character.character_name}</h4>
					<h4 className='my-player-name w-100 my-player-text white mb-0'>{character.player_name}</h4>
					<h4 className='my-player-stats-hp my-player-text white mb-0'>
						<Tooltip title='HP Current' getPopupContainer={getContainer}>
							{character.hp}
						</Tooltip>
					</h4>
					<h4 className='my-player-stats-ac my-player-text white mb-0'>
						<Tooltip title='Armour Class' placement='left' getPopupContainer={getContainer}>
							{character.ac}
						</Tooltip>
					</h4>
					<h4 style={{ backgroundColor: character.color }} className={'my-player-stats-num my-player-text white mb-0'}>
						{character.playerNUM}
					</h4>
				</div>
			</Popover>
		)
	} else if (type === 'oponent') {
		viewHelper.push(
			<Popover
				onVisibleChange={handlePopoverVisibleChange}
				visible={popoverVisibility}
				placement='leftTop'
				content={content}
				title='Dice History'
				key={type}
			>
				<div
					className='player-container'
					onMouseEnter={() => handlePlayerMouseEnter()}
					onMouseLeave={() => setPopoverVisibility(false)}
					onClick={isDm ? () => setIsCharacterViewVisible(true) : undefined}
				>
					{alertEl}
					<div
						className={
							characterImageGlow
								? 'player background-settings-v2 character-image-glow'
								: 'player background-settings-v2'
						}
						style={{
							backgroundImage: `url(${character.imageISfile})`,
							backgroundColor: 'white',
						}}
					/>
					<div className='player-box'>
						<img width={100} height={127} src={playerBoxImg} />
					</div>
					<h4 className='character-name w-100 player-text white mb-0'>{character.character_name}</h4>
					<h4 className='player-name w-100 player-text white mb-0'>{character.player_name}</h4>
					<h4 className='player-stats-hp player-text white mb-0'>
						<Tooltip title='HP Current' getPopupContainer={getContainer}>
							{character.hp}
						</Tooltip>
					</h4>
					<h4 className='player-stats-ac player-text white mb-0'>
						<Tooltip title='Armour Class' placement='left' getPopupContainer={getContainer}>
							{character.ac}
						</Tooltip>
					</h4>
					<h4 style={{ backgroundColor: character.color }} className={'player-stats-num player-text white mb-0'}>
						{character.playerNUM}
					</h4>
				</div>
			</Popover>
		)
	} else if (type === 'dm_v1') {
		viewHelper.push(
			<Popover
				onVisibleChange={handlePopoverVisibleChange}
				visible={popoverVisibility}
				placement='leftTop'
				content={content}
				title='Dice History'
				key={type}
				// trigger="hover"
			>
				<div
					className='my-player-container'
					onMouseEnter={() => handlePlayerMouseEnter()}
					onMouseLeave={() => setPopoverVisibility(false)}
					onClick={() => setIsDmModelVisible(true)}
				>
					{alertEl}
					<div
						className={
							characterImageGlow || dmScretRollGlow
								? 'dm-image background-settings dm-character-image-glow'
								: 'dm-image background-settings'
						}
						style={{
							backgroundImage: `url(${dataGame.session.dm_imageISfile || dmImage})`,
							backgroundColor: 'white',
						}}
					/>
					<div className='my-player-box'>
						<img width={238} height={320} src={playerBoxImg} />
					</div>
				</div>
			</Popover>
		)
	} else if (type === 'dm_v2') {
		viewHelper.push(
			<Popover
				onVisibleChange={handlePopoverVisibleChange}
				visible={popoverVisibility}
				placement='leftTop'
				content={content}
				title='Dice History'
				key={type}
			>
				<div
					className='player-container ml-auto align-self-stretch'
					onMouseEnter={() => handlePlayerMouseEnter()}
					onMouseLeave={() => setPopoverVisibility(false)}
				>
					{alertEl}
					<div
						className={
							characterImageGlow || dmScretRollGlow
								? 'dm-image background-settings character-image-glow'
								: 'dm-image background-settings'
						}
						style={{
							backgroundImage: `url(${dataGame.session.dm_imageISfile || dmImage})`,
							backgroundColor: 'white',
						}}
					/>
					<div className='player-box'>
						<img width={110} height={148} src={playerBoxImg} />
					</div>
				</div>
			</Popover>
		)
	}
	return (
		<>
			{viewHelper}

			<PrimaryModel
				handleCancel={() => setIsCharacterViewVisible(false)}
				isModalVisible={isCharacterViewVisible}
				title='Character View'
				bodyStyle={{ paddingBottom: 0, paddingTop: 5 }}
			>
				<CharacterPopup isDm={isDm} playerNum={character.playerNUM} character_sheet={character_sheet} />
			</PrimaryModel>
		</>
	)
}

export default SingleCharacter
