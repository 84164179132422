import { CALL_GAME_DATA, REMOVE_GAME_DATA, SET_GAME_DATA } from '../../constants'

// **  Initial State
const initialState = {
	loading: false,
	data: null,
}

const gameDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case CALL_GAME_DATA:
			return { ...state, loading: true, data: null }
		case SET_GAME_DATA:
			return {
				...state,
				loading: false,
				data: {
					...action.payload,
					scene: {
						...action.payload.scene,
						overlayISfile: action.payload.scene.overlayISfile
							? action.payload.scene.overlayISfile + '?random_number=' + new Date().getTime()
							: action.payload.scene.overlayISfile,
						imageISfile: action.payload.scene.imageISfile
							? action.payload.scene.imageISfile + '?random_number=' + new Date().getTime()
							: action.payload.scene.imageISfile,
					},
					session: {
						...action.payload.session,
						display_fileISfile: action.payload.session.display_fileISfile
							? action.payload.session.display_fileISfile + '?random_number=' + new Date().getTime()
							: action.payload.session.display_fileISfile,
					},
				},
			}
		case REMOVE_GAME_DATA:
			return { ...state, loading: false, data: null }
		default:
			return state
	}
}

export default gameDataReducer
