import { Col, Row, Input, Spin, Form, Button } from 'antd'
import React, { Fragment } from 'react'
import { useEffect, useState } from 'react'
import { callLoadGame, callToApi } from '../../utils'
import { useDispatch } from 'react-redux'
import { setGameDataAction } from './../../redux/actions/gameData/index'
import { getRestPlaceholder } from './ChararcterStatsTab'
import { v4 as uuidv4 } from 'uuid';

const InventoryTab = ({ character_sheet, playerNum, isDm }) => {
	const [nameforHighlight, setNameforHighlight] = useState('')
	const [nameforError, setNameforError] = useState('')
	const [nameforLoading, setNameforLoading] = useState('')
	const [loadedCharacter, setLoadedCharacter] = useState(null)
	const [characterLoading, setCharacterLoading] = useState(false)
	const [characterError, setCharacterError] = useState('')
	const dispatch = useDispatch()

	const { inventory } = character_sheet
	const inventoryArr = Object.entries(inventory)

	const loadCharacter = async () => {
		setCharacterLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'load_character',
			player_id: playerId,
			playerNUM: playerNum,
		}
		try {
			const { data } = await callToApi({ body })
			setLoadedCharacter({ ...data })
			setCharacterLoading(false)
			setCharacterError('')
		} catch (err) {
			setCharacterError('something went wrong')
			setCharacterLoading(false)
			console.log(err.response)
		}
	}

	const makeHighlightSuccess = () => {
		setTimeout(() => {
			setNameforHighlight('')
		}, 2000)
	}

	const makeHighlightError = () => {
		setTimeout(() => {
			setNameforError('')
		}, 2000)
	}

	const handleBlur = (e) => {
		setNameforLoading(e.target.name)
		updateCharacter(e.target.name, e.target.value)
	}

	const updateCharacter = async (f, v) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'update_character',
			player_id: playerId,
			playerNUM: playerNum,
			field: f,
			value: v,
		}
		try {
			const { data } = await callToApi({ body })

			setLoadedCharacter(data)
			setNameforLoading('')
			setNameforHighlight(f)
			makeHighlightSuccess()
			loadGame()
		} catch (err) {
			setNameforLoading('')
			setNameforError(f)
			makeHighlightError()
			console.log(err)
		}
	}

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		loadCharacter()
	}, [])

	if (characterLoading) {
		return (
			<div className='text-center my-3'>
				<Spin className='mr-2' /> loading character ...
			</div>
		)
	}

	if (characterError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => loadCharacter()}>Try again</Button>
			</div>
		)
	}

	if (!loadedCharacter) return <h4>Inventory View</h4>
	return (
		<div className='scrollable-one '>
			<Row align='bottom' gutter={[12, 2]} className='mb-4'>
				{inventoryArr.map((item, i) => {
					if (item.includes('break')) return getRestPlaceholder(i)
					let FieldComponent = Input
					let colProps = { key: i, span: 24, sm: 8, md: 6 }
					if (i > 2) {
						colProps = { key: i, span: 12 }
						FieldComponent = Input.TextArea
					}
					return (
						<Fragment key={uuidv4()}>
							{i === 3 && getRestPlaceholder(i + '-breaker')}
							<Col {...colProps}>
								<div>
									<label className='character-stats-label'>{item[1]}</label>
									<Form.Item
										className={
											nameforHighlight === item[0]
												? 'highlighted'
												: nameforError === item[0]
												? 'highlighted-error'
												: null
										}
									>
										<FieldComponent
											suffix={nameforLoading === item[0] ? <Spin size='small' /> : null}
											name={item[0]}
											onBlur={handleBlur}
											placeholder={item[1]}
											defaultValue={loadedCharacter[`${item[0]}`]}
										/>
									</Form.Item>
								</div>
							</Col>
						</Fragment>
					)
				})}
			</Row>
		</div>
	)
}

export default InventoryTab
