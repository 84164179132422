import axios from 'axios'

export const callToApi = ({ body }) => {
	let queryP = new URLSearchParams(window.location.search)
	let gameId = queryP.get('g') || '-1'
	const params = {
		apikey: process.env.REACT_APP_API_KEY,
		apisecret: process.env.REACT_APP_API_SECRET,
		game_id: gameId,
		...body,
	}
	return axios({
		method: 'post',
		url: 'https://ww1.roleplaymagic.com/?api',
		data: params,
		// crossDomain: true,
		// withCredentials: true,
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		  }
	})
}

export const callLoadGame = (override = {}) => {
	let queryP = new URLSearchParams(window.location.search)
	let playerId = queryP.get('access') || '-1'
	const body = { api_method: 'load_game', player_id: playerId, ...override }
	return callToApi({ body })
}

export const callLogout = () => {
	let queryP = new URLSearchParams(window.location.search)
	let playerId = queryP.get('access') || '-1'
	const body = { api_method: 'logoff', player_id: playerId }
	return callToApi({ body })
}
