import { SET_CHAT_LIST, CALL_CHAT_LIST } from "../../constants";

export const callChatListAction = () => {
  return {
    type: CALL_CHAT_LIST,
  };
};

export const setChatListAction = (data) => {
  return {
    type: SET_CHAT_LIST,
    payload: data,
  };
};
