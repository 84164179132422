import {
  CALL_APP_SETTINGS,
  FAILED_APP_SETTINGS,
  SET_APP_SETTINGS,
} from "../../constants";

export const setAppSettingsAction = (data) => {
  return {
    type: SET_APP_SETTINGS,
    payload: data,
  };
};

export const callAppSettingsAction = () => {
  return {
    type: CALL_APP_SETTINGS,
  };
};

export const failedAppSettingsAction = (error) => {
  return {
    type: FAILED_APP_SETTINGS,
    payload: error,
  };
};
