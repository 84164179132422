import React from "react";
import { Jutsu } from "react-jutsu"; // Component
import { useJitsi } from "react-jutsu"; // Custom hook
import { Spin } from "antd";
const JistsiMeeting = () => {
  const jitsiConfig = {
    domain: "meet.jit.si",
    roomName: "konohFrequentDimensionsShedBroadlya",
    displayName: "RolePlayMagic",
    password: "dattebayo",
    subject: "fan",
    parentNode: "jitsi-container",
  };

  const { loading, error, jitsi } = useJitsi(jitsiConfig);

  return (
    <div>
      {error && <p>{error}</p>}
      <div
        style={{
          width: "100%",
          height: "500px",
          background: "#151515",
        }}
        id={jitsiConfig.parentNode}
      ></div>
    </div>
  );
};

export default JistsiMeeting;
