export const showPopoverAction = (playerNUM) => {
  return {
    type: "SHOW_P",
    payload: playerNUM,
  };
};

export const hidePopoverAction = (playerNUM) => {
  return {
    type: "HIDE_P",
    payload: playerNUM,
  };
};

export const SecretRollGlowOnAction = () => {
  return {
    type: "SECRET_ROLL_GLOW_ON",
    payload: true,
  };
};

export const SecretRollGlowOffAction = () => {
  return {
    type: "SECRET_ROLL_GLOW_OFF",
    payload: true,
  };
};
