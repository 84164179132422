import React from 'react'
import { FileImageOutlined, EditFilled, FileOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import { useEffect, useRef, useState } from 'react'
import OverlayCanvas from './OverlayCanvas'
import PlayGround from './PlayGround'
import { callToApi } from '../../utils'
import PrimaryModel from '../PrimaryModel'
import img16 from '../../assets/16.png'
import img32 from '../../assets/32.png'
import img48 from '../../assets/48.png'
import img64 from '../../assets/64.png'
import draw from '../../assets/pencil.png'
import erase from '../../assets/eraser.png'
import { useSelector, useDispatch } from 'react-redux'
import EditSceneModal from '../DmPopup/EditSceneModal'
import { Tabs } from 'antd'
import Scenes from '../DmPopup/Scenes'
import QuickScenes from '../DmPopup/QuickScenes'
import QuickNotesFiles from '../DmPopup/QuickNotesFiles'
import { message } from 'antd'
import { setGameDataAction } from './../../redux/actions/gameData/index'

const { TabPane } = Tabs
const audio = new Audio()
const imageExt = ['.png', '.jpeg', '.jpg', '.svg', '.webp']

const GameMap = ({ ui_images, dataGame }) => {
	const dispatch = useDispatch()
	const gameData = useSelector((state) => state.gameData.data)
	const appSettings = useSelector((state) => state.appSettings.data)
	const { session, scene } = gameData || {}
	const [applying, setApplying] = useState(null)
	const { muteStatus } = useSelector((state) => state.backgroundMusic)

	const cref = useRef()
	const [overlayCanvasMouseAction, setOverlayCanvasMouseAction] = useState(null)
	const [showEditElement, setShowEditElement] = useState(false)
	const [isSceneModelVisible, setIsSceneModelVisible] = useState(false)
	const [isLibraryModelVisible, setIsLibraryModelVisible] = useState(false)
	const [scenes, setScenes] = useState(null)
	const [scenesLoading, setScenesLoading] = useState(false)
	const [scenesError, setScenesError] = useState('')
	const [isDragging, setIsDragging] = useState(false)
	const [isDraw, setIsDraw] = useState(true)
	const [fileExtension, setFileExtension] = useState('')
	const [quickEditScreen, setQuickEditScreen] = useState(null)

	let gameMapEl = document.getElementById('game-map-container')
	const [dPosition, setDPosition] = useState({
		name: null,
		posX: 500,
		posY: 500,
	})
	const [ePosition, setEPosition] = useState({
		name: null,
		posX: 500,
		posY: 500,
	})

	const { positions, players, elements } = dataGame
	const [playersList, setPlayerList] = useState([])
	const [elementList, setElementList] = useState([])
	const [selectedCircle, setSelectedCircle] = useState('')
	const [mouseIcon, setMouseIcon] = useState('')
	const [hogItem, setHogItem] = useState({ posX: 500, posY: 500 })
	const [isDmModelVisible, setIsDmModelVisible] = useState(false)

	useEffect(() => {}, [fileExtension])

	useEffect(() => {
		let temp = []
		for (let counter = 1; counter <= players.length; counter++) {
			temp.push({
				name: counter,
				posX: Number(positions[`p${counter}`].split(',')[0]),
				posY: Number(positions[`p${counter}`].split(',')[1]),
				color: players[counter - 1]?.color,
			})
		}
		setPlayerList(temp)
		let temp2 = []
		elements.forEach((element) => {
			let counter = Number(element.elementNUM)
			temp2.push({
				name: element.elementNUM,
				posX: Number(positions[`m${counter}`].split(',')[0]),
				posY: Number(positions[`m${counter}`].split(',')[1]),
			})
		})
		setElementList(temp2)

		setHogItem({
			posX: Number(positions['hog'].split(',')[0]),
			posY: Number(positions['hog'].split(',')[1]),
		})
	}, [positions, elements])

	const loadScenes = async () => {
		setScenesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_scenes',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			setScenes(data.scenes)
			setScenesLoading(false)
			setScenesError('')
		} catch (err) {
			setScenesLoading(false)
			setScenesError('something went wrong')
			console.log('error')
			console.log(err)
		}
	}

	const checkExtension = () => {
		let file = session.display_fileISfile
		let ext = file.slice(file.lastIndexOf('.'))
		const exist = imageExt.some((x) => ext.includes(x))
		if (exist) {
			setFileExtension('image')
		} else if (ext == '.mp3') {
			setFileExtension('audio')
			backgroundSound('play')
		} else {
			setFileExtension('')
		}
	}

	const hideFile = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'hide_file',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('File removed!!')
		} catch (err) {
			console.log(err)
			message.error('Error!!')
		}
	}

	const backgroundSound = (action) => {
		if (audio) {
			if (action === 'stop') {
				audio.pause()
				audio.currentTime = 0
			} else {
				audio.src = session.display_fileISfile
				audio.play()
				audio.volume = 1
				audio.loop = true
			}
		}
	}

	useEffect(() => {
		if (session.display_fileISfile) {
			checkExtension()
		} else {
			backgroundSound('stop')
			setFileExtension('')
		}
	}, [session])

	useEffect(() => {
		if (muteStatus) {
			audio.volume = 0
		} else {
			audio.volume = 1
		}
	}, [muteStatus])

	useEffect(() => {
		loadScenes()
	}, [])

	const addElement = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		let gameId = queryP.get('g')

		const body = {
			api_method: 'add_element',
			player_id: playerId,
			apikey: process.env.REACT_APP_API_KEY,
			apisecret: process.env.REACT_APP_API_SECRET,
			game_id: gameId,
		}
		try {
			let { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
		}
	}
	/////////////////////
	const handleMouseDown = (n) => {
		const clickedPlayer = playersList.find((x) => x.name === n)
		setDPosition({
			posX: clickedPlayer.posX,
			name: n,
			posY: clickedPlayer.posY,
		})
		setIsDragging(true)
		setSelectedCircle('player')
	}
	////////////////////
	const handleMouseDownElement = (n) => {
		const clickedElement = elementList.find((x) => x.name === n)
		setEPosition({
			posX: clickedElement.posX,
			name: n,
			posY: clickedElement.posY,
		})
		setIsDragging(true)
		setSelectedCircle('element')
	}

	const handleMouseDownHog = () => {
		setHogItem({
			posX: hogItem.posX,
			posY: hogItem.posY,
		})
		setIsDragging(true)
		setSelectedCircle('hog')
	}

	const moveCharacter = async (playernum, x, y) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'move_character',
			player_id: playerId,
			playerNUM: playernum,
			xpos: x,
			ypos: y,
		}
		try {
			let { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
		}
	}
	const moveElement = async (elementNUM, x, y) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'move_element',
			player_id: playerId,
			elementNUM: elementNUM,
			xpos: x,
			ypos: y,
		}
		try {
			let { data } = await callToApi({ body })
		} catch (err) {
			console.log(err)
		}
	}
	const hanldeMouseUp = () => {
		if (isDragging) {
			if (selectedCircle === 'player') {
				moveCharacter(dPosition.name, dPosition.posX, dPosition.posY)
				let temp = [...playersList]
				temp.splice(dPosition.name - 1, 1, {
					...temp[dPosition.name - 1],
					...dPosition,
				})
				setPlayerList(temp)
			} else if (selectedCircle === 'element') {
				moveElement(ePosition.name, ePosition.posX, ePosition.posY)
				let temp = [...elementList]
				let aa = temp.map((i) => i.name).indexOf(ePosition.name)

				temp.splice(aa, 1, {
					...temp[aa],
					...ePosition,
				})

				setElementList(temp)
			} else if (selectedCircle === 'hog') {
				moveElement('hog', hogItem.posX, hogItem.posY)
			}
		}
		setIsDragging(false)
	}

	const handleMouseMove = (event) => {
		let rect = gameMapEl?.getBoundingClientRect()
		let x = event.clientX - rect?.left - 10
		let y = event.clientY - rect?.top - 17
		if (isDragging) {
			let maxLimit = y < 60 || x < 44 || x > 757
			if (selectedCircle === 'player') {
				if (maxLimit || y > 763) {
				} else {
					setDPosition({
						...dPosition,
						posY: y,
						posX: x,
					})
				}
			} else if (selectedCircle === 'element') {
				if (maxLimit || y > 870) {
				} else {
					setEPosition({
						...ePosition,
						posY: y,
						posX: x,
					})
				}
			} else if (selectedCircle === 'hog') {
				if (maxLimit || y > 763) {
				} else {
					setHogItem({
						posY: y,
						posX: x,
					})
				}
			}
		}
	}

	const func1 = () => {
		setOverlayCanvasMouseAction(null)
		setMouseIcon('')
	}

	const editElement = () => {
		showEditElement(true)
	}

	const isDM = dataGame.player.playerNUM === 99
	const quickBtnStyles = {
		backgroundImage: `url(${ui_images.button_small})`,
		position: 'relative',
		bottom: '68px',
		left: '5px',
	}

	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	const applyScene = async (sceneId) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'apply_scene',
			scene_id: sceneId,
			player_id: playerId,
		}
		try {
			setApplying(sceneId)
			const { data } = await callToApi({ body })
			loadGame()
			// message.success('Scene Applied')
			setApplying(null)
			setQuickEditScreen(null)
		} catch (err) {
			setApplying(null)
			console.log('error', err)
			message.error('Action Failed')
		}
	}

	return (
		<div className='game-view mb-4 mt-4'>
			<div
				className='game-map-container'
				id='game-map-container'
				onMouseUp={hanldeMouseUp}
				onMouseMove={handleMouseMove}
				style={{
					cursor: mouseIcon == 'draw' ? `url(${draw}),pointer` : mouseIcon == 'erase' && `url(${erase}),pointer`,
				}}
			>
				{isDM && (
					<>
						<div className='dm-elements-area'></div>
					</>
				)}
				{isDM && (
					<>
						<OverlayCanvas
							overlayCanvasMouseAction={overlayCanvasMouseAction}
							ref={cref}
							setIsDraw={setIsDraw}
							func1={func1}
						/>
					</>
				)}
				<img
					className='game-map background-settings'
					src={scene.imageISfile || appSettings?.ui_images?.no_map}
					style={{ userSelect: 'none' }}
				></img>
				{scene.blocks_acrossNUM !== 0 &&
					(scene.blocks_acrossNUM == '16' ? (
						<div style={{ backgroundImage: `url(${img16})` }} className='map-grid'></div>
					) : scene.blocks_acrossNUM == '32' ? (
						<div style={{ backgroundImage: `url(${img32})` }} className='map-grid'></div>
					) : scene.blocks_acrossNUM == '48' ? (
						<div style={{ backgroundImage: `url(${img48})` }} className='map-grid'></div>
					) : scene.blocks_acrossNUM == '60' ? (
						<div style={{ backgroundImage: `url(${img64})` }} className='map-grid'></div>
					) : (
						''
					))}
				<div className='game-map-wrap'>
					<img width={820} height={820} src={ui_images.map_wrapper} style={{ userSelect: 'none' }} draggable={false} />
				</div>
				<PlayGround
					dataGame={dataGame}
					handleMouseDown={handleMouseDown}
					handleMouseDownElement={handleMouseDownElement}
					handleMouseDownHog={handleMouseDownHog}
					dPosition={dPosition}
					ePosition={ePosition}
					isDragging={isDragging}
					setIsDragging={setIsDragging}
					playersList={playersList}
					elementList={elementList}
					hogItem={hogItem}
					isDraw={isDraw}
				/>

				<h2 style={{ userSelect: 'none' }} className='game-title white'>
					{scene.name}
				</h2>
				{fileExtension === 'image' && (
					<>
						<div
							className='background-settings-v2'
							style={{
								backgroundImage: `url(${session.display_fileISfile})`,
								height: '705px',
								width: '726px',
								zIndex: 20,
								position: 'absolute',
								top: '72px',
								left: '48px',
								cursor: isDM ? 'pointer' : 'auto',
								backgroundColor: 'black',
							}}
							onClick={isDM ? hideFile : null}
						/>
						{isDM && (
							<button
								onClick={hideFile}
								style={{
									position: 'absolute',
									top: '78px',
									background: 'none',
									border: 'none',
									color: 'white',
									zIndex: 20,
									right: '48px',
									cursor: 'pointer',
								}}
							>
								{' '}
								<span class='material-icons'>close</span>
							</button>
						)}
					</>
				)}
			</div>

			<footer className='mb-15'>
				{dataGame.player.playerNUM === 99 && (
					<>
						<Popover
							content={
								<div>
									<div className='mb-5'>
										<Button
											block
											type='dashed'
											onClick={() => {
												setOverlayCanvasMouseAction('erase')
												setMouseIcon('erase')
												mouseIcon == 'erase' && func1()
											}}
											style={{
												background: mouseIcon == 'erase' && 'green',
												border: mouseIcon == 'erase' && 'none',
												color: mouseIcon == 'erase' && 'white',
											}}
										>
											Erase
										</Button>
									</div>
									<div className='mb-5'>
										<Button
											block
											type='dashed'
											style={{
												background: mouseIcon == 'draw' && 'green',
												border: mouseIcon == 'draw' && 'none',
												color: mouseIcon == 'draw' && 'white',
											}}
											onClick={() => {
												setOverlayCanvasMouseAction('draw')
												setMouseIcon('draw')
												mouseIcon == 'draw' && func1()
											}}
										>
											Draw
										</Button>
									</div>
									<div className='mb-5'>
										<Button
											block
											type='dashed'
											onClick={() => {
												cref.current.clearCanvas()
											}}
										>
											Clear
										</Button>
									</div>
									<div className='mb-5'>
										<Button
											block
											type='dashed'
											onClick={() => {
												cref.current.fillCanvas()
											}}
										>
											Obscure
										</Button>
									</div>

									<div className='mb-5'>
										<Button block type='dashed' onClick={addElement}>
											Add Element
										</Button>
									</div>
									<div className='mb-5'>
										<Button block type='dashed' onClick={() => setIsSceneModelVisible(true)}>
											Edit Scene
										</Button>
									</div>
									<div className='mb-5'>
										<Button block type='dashed' onClick={() => setIsDmModelVisible(true)}>
											List Scenes
										</Button>
									</div>
								</div>
							}
							title='Functions'
							trigger='click'
						>
							<button style={quickBtnStyles} className='custom-button-settings small-button-settings white'>
								<EditFilled />
							</button>
						</Popover>
						<Popover
							key={quickEditScreen ? 'clicked' : 'non-clicked'}
							destroyTooltipOnHide
							placement='leftTop'
							title='Scenes'
							content={<QuickScenes key={Date.now()} setQuickEditScreen={setQuickEditScreen} />}
						>
							<button style={quickBtnStyles} className='custom-button-settings small-button-settings white'>
								<FileImageOutlined />
							</button>
						</Popover>
						<Popover
							destroyTooltipOnHide
							placement='leftTop'
							title='Files'
							content={<QuickNotesFiles key={Date.now()} />}
						>
							<button style={quickBtnStyles} className='custom-button-settings small-button-settings white'>
								<FileOutlined rotate={-180} />
							</button>
						</Popover>
					</>
				)}
				<PrimaryModel
					handleCancel={() => setIsSceneModelVisible(false)}
					isModalVisible={isSceneModelVisible}
					title='Edit Scene'
					bodyStyle={{ paddingBottom: 0, paddingTop: 5 }}
				>
					<EditSceneModal
						closeParentModel={() => setIsSceneModelVisible(false)}
						sEdit={dataGame.scene}
						loadScenes={loadScenes}
					/>
				</PrimaryModel>
				<PrimaryModel
					handleCancel={() => setQuickEditScreen(null)}
					isModalVisible={!!quickEditScreen}
					title='Edit Scene'
					bodyStyle={{ paddingBottom: 0, paddingTop: 5 }}
				>
					<EditSceneModal
						closeParentModel={() => setQuickEditScreen(null)}
						sEdit={quickEditScreen}
						applyScene={applyScene}
						isApplying={applying === quickEditScreen?._id}
						loadScenes={loadScenes}
					/>
				</PrimaryModel>
				<PrimaryModel
					handleCancel={() => setIsDmModelVisible(false)}
					isModalVisible={isDmModelVisible}
					title='Dungeon Master'
					bodyStyle={{ paddingBottom: 0, paddingTop: 5 }}
				>
					<Tabs defaultActiveKey='1'>
						<TabPane
							tab={
								<>
									<FileImageOutlined />
									Scenes
								</>
							}
							key='1'
						>
							<Scenes closeParentModel={() => setIsDmModelVisible(false)} />
						</TabPane>
					</Tabs>
				</PrimaryModel>
			</footer>
		</div>
	)
}

export default GameMap
