import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, message, Spin, Row } from 'antd'
import EllipsisText from 'react-ellipsis-text'
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons'

import { callToApi } from '../../utils'
import PrimaryModel from '../PrimaryModel'
import AddLibraryModal from './AddLibraryModal'

const LibraryPopup = () => {
	const { data: dataGame = {} } = useSelector((state) => state.gameData)
	const [notesLoading, setNotesLoading] = useState(false)
	const [notesError, setNotesError] = useState('')
	const [notesList, setNotesList] = useState(null)
	const [addFileModal, setAddFileModal] = useState(false)

	const loadLibrary = async () => {
		setNotesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_library',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			setNotesList(data.library)
			setNotesLoading(false)
			setNotesError('')
		} catch (err) {
			setNotesLoading(false)
			setNotesError('something went wrong')
			console.log(err)
		}
	}

	const deleteNoteFile = async (id) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'delete_library',
			player_id: playerId,
			library_id: id,
		}
		try {
			const { data } = await callToApi({ body })
			// message.success('File Deleted')
			loadLibrary()
		} catch (err) {
			console.log(err)
			message.error('Action Failed')
		}
	}

	useEffect(() => {
		loadLibrary()
	}, [])

	const closeModal = () => {
		setAddFileModal(false)
	}
	if (notesList) {
		return (
			<>
				<div className='d-flex flex-wrap' style={{ justifyContent: 'space-evenly' }}>
					{notesList.map((item) => (
						<div className='new-library-card-v2 standard-card' key={item.url}>
							<div className='box-1'>
								<div
									onClick={() => window.open(item.url, '_blank')}
									style={{
										backgroundImage: `url(${item.thumbnail})`,
										cursor: 'pointer',
									}}
									className={'new-lib-image-v2'}
								></div>
							</div>
							<div className='box-2'>
								<div className='new-lib-title-v2'>
									<EllipsisText className='scene-title' text={item.name || ''} length={34} />
								</div>

								{item.library_id && dataGame.player?.playerNUM === 99 && (
									// <div className='new-lib-del-v2'>
									<Row justify='end'>
										<Button
											danger
											type='link'
											shape='circle'
											onClick={() => deleteNoteFile(item.library_id)}
											icon={<DeleteOutlined />}
											size='small'
											className='action-card'
										/>
									</Row>
									// </div>
								)}
							</div>
						</div>
					))}
					{dataGame.player?.playerNUM === 99 && (
						<div className='new-library-card-v2 standard-card'>
							<div className='custom-add-button-wrap'>
								<Button type='primary' icon={<FileTextOutlined />} onClick={() => setAddFileModal(true)}>
									Add File
								</Button>
								{/* <button className='custom-add-button' onClick={() => setAddFileModal(true)}>
										<FileTextOutlined className='mr-1' /> Add File
									</button> */}
							</div>
						</div>
					)}
				</div>

				<PrimaryModel handleCancel={() => setAddFileModal(false)} isModalVisible={addFileModal} title='Add Library'>
					<AddLibraryModal loadLibrary={loadLibrary} closeModal={closeModal} />
				</PrimaryModel>
			</>
		)
	}
	if (notesLoading) {
		return (
			<>
				<div className='text-center my-3'>
					<Spin className='mr-2' /> loading Files ...
				</div>
			</>
		)
	}

	if (notesError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => listNotesFile()}>Try again</Button>
			</div>
		)
	}

	return <h4>Files</h4>
}

export default LibraryPopup
