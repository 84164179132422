// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import gameData from "./gameData";
import appSettings from "./appSettings";
import popoverRedux from "./characterPopover";
import chatList from "./chatList/index";
import secretRoll from "./secretRollGlow";
import backgroundMusic from "./backgroundMusic";
const rootReducer = combineReducers({
  gameData,
  appSettings,
  popoverRedux,
  chatList,
  secretRoll,
  backgroundMusic,
});

export default rootReducer;
