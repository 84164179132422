import { Spin } from 'antd'
import React from 'react'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { callLoadGame, callToApi } from '../../utils'
import EllipsisText from 'react-ellipsis-text'
import { Typography } from 'antd'
import { setGameDataAction } from './../../redux/actions/gameData/index'
import { useDispatch } from 'react-redux'

const { Text } = Typography
const NewsPanelPopup = () => {
	const [loadNews, setLoadNews] = useState(null)
	const dispatch = useDispatch()
	const loadGame = async () => {
		try {
			let { data } = await callLoadGame()
			dispatch(setGameDataAction(data))
		} catch (err) {
			console.log(err)
		}
	}

	const listNews = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_news',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })
			setLoadNews(data.news)
		} catch (err) {
			console.log(err.response)
		}
	}

	useEffect(() => {
		listNews()
		loadGame()
	}, [])
	return (
		<>
			<div>
				<div>
					{loadNews !== null ? (
						loadNews?.length !== 0 ? (
							loadNews?.map((item, i) => (
								<div
									className={`standard-card ${i + 1 === loadNews.length ? '' : 'mb-5'} d-flex`}
									key={item._dateadded}
								>
									<div
										style={{
											flexShrink: 0,
											width: '280px',
											height: '157px',
											backgroundImage: `url(${item.imageISfile})`,
											borderTopLeftRadius: 5,
											borderBottomLeftRadius: 5,
											clipPath: 'ellipse(86% 230px at 14% 29%)',
										}}
										className='background-settings'
									></div>
									<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }} className='ml-5 mt-3'>
										<div>
											<div>
												<h4 style={{ fontWeight: 600 }} className='mb-1'>
													{item.name}
												</h4>
											</div>

											<div className='mr-2'>
												{item.newsISsmallplaintextbox && (
													<div style={{ fontSize: '15px' }}>
														<EllipsisText
															text={item.newsISsmallplaintextbox}
															length={160}
															style={{ fontSize: 14, lineHeight: 0.7, color: '#0000009c' }}
														/>
														<a href={item.urlISsmallplaintextbox} target='_blank'>
															{'  Read more'}
														</a>
													</div>
												)}
											</div>
										</div>

										<div style={{ marginTop: 'auto' }}>
											<div style={{ width: '99.9%', borderTop: '1px solid', borderTopColor: '#f0f2ff' }} />

											<div
												style={{
													fontSize: 12,
													fontWeight: 600,
													float: 'right',
													margin: '8px 10px 8px 0',
												}}
											>
												<Text type='secondary'>{moment(item._dateadded).format('MMM DD').toUpperCase()}</Text>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<div>no News yet</div>
						)
					) : (
						<div className='text-center'>
							<Spin className='mr-2' /> Loading News
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default NewsPanelPopup
