export const CALL_GAME_DATA = "CALL_GAME_DATA";
export const SET_GAME_DATA = "SET_GAME_DATA";
export const REMOVE_GAME_DATA = "REMOVE_GAME_DATA";
export const CALL_APP_SETTINGS = "CALL_APP_SETTINGS";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const FAILED_APP_SETTINGS = "FAILED_APP_SETTINGS";
export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";
export const CALL_CHAT_LIST = "CALL_CHAT_LIST";
export const SET_CHAT_LIST = "SET_CHAT_LIST";
export const REMOVE_CHAT_LIST = "REMOVE_CHAT_LIST";
