import React, { useState, useEffect } from 'react'
import { Button, message, Spin, Row } from 'antd'
import { FileTextOutlined, DeleteOutlined } from '@ant-design/icons'

import { callToApi } from '../../utils'
import PrimaryModel from '../PrimaryModel'
import EllipsisText from 'react-ellipsis-text'
import EditSceneAddFileModal from './EditSceneAddFileModal'
import { useSelector } from 'react-redux'
import soundImage from './../../assets/soundImage.png'

const EditSceneFilesView = ({ scene_id, closeParentModel }) => {
	const [notesLoading, setNotesLoading] = useState(false)
	const [notesError, setNotesError] = useState('')
	const [notesList, setNotesList] = useState(null)
	const [addFileModal, setAddFileModal] = useState(false)
	const [currentlySelectedSceneLoading, setCurrentlySelectedSceneLoading] = useState(false)

	const { data: dataGame } = useSelector((state) => state.gameData)
	const { _id } = dataGame.scene
	const { session } = dataGame

	const listNotes = async () => {
		setNotesLoading(true)
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'list_notes',
			player_id: playerId,
			type: 'SCENE',
			scene_id: scene_id,
		}
		try {
			const { data } = await callToApi({ body })
			const notes = data.notes.filter((x) => !x.note_text && x.note_file)
			setNotesList(notes)
			setNotesLoading(false)
		} catch (err) {
			console.log(err)
			setNotesError('Something went wrong')
		}
	}

	const showFile = async (file) => {
		if (scene_id == _id) {
			let queryP = new URLSearchParams(window.location.search)
			let playerId = queryP.get('access')
			const body = {
				api_method: 'show_file',
				player_id: playerId,
				file_url: file,
			}
			try {
				const { data } = await callToApi({ body })
				// message.success('File applied!!')
				closeParentModel?.()
			} catch (err) {
				console.log(err)
			}
		} else {
			message.error('you first need to apply this scene!')
		}
		setCurrentlySelectedSceneLoading(false)
	}

	const hideFile = async () => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'hide_file',
			player_id: playerId,
		}
		try {
			const { data } = await callToApi({ body })

			// message.success('File removed!!')
		} catch (err) {
			console.log(err)
			message.error('Error!!')
		}
		setCurrentlySelectedSceneLoading(false)
	}

	const deleteNote = async (id) => {
		let queryP = new URLSearchParams(window.location.search)
		let playerId = queryP.get('access')
		const body = {
			api_method: 'delete_note',
			type: 'SCENE',
			player_id: playerId,
			note_id: id,
			scene_id: scene_id,
		}
		try {
			const { data } = await callToApi({ body })
			listNotes()
		} catch (err) {
			console.log(err)
		}
	}

	const onFileClick = (file) => {
		setCurrentlySelectedSceneLoading(true)

		if (session.display_fileISfile) {
			if (session.display_fileISfile === file) {
				hideFile()
			} else {
				showFile(file)
			}
		} else {
			showFile(file)
		}
	}

	useEffect(() => {
		listNotes()
	}, [])

	if (notesList) {
		return (
			<>
				<div className='scrollable-lib pa-5'>
					<div className='d-flex flex-wrap' style={{ justifyContent: 'space-evenly' }}>
						{notesList.map((item) => {
							return (
								<>
									<div className='new-library-card-v2 standard-card'>
										<div className='box-1' style={{ textAlign: 'right' }}>
											<div
												style={{
													backgroundImage: `url(${
														item.note_file?.slice(item.note_file?.lastIndexOf('.')) === '.mp3'
															? soundImage
															: item.note_file
													})`,
												}}
												className={'new-lib-image-v2'}
											>
												{/* {session.display_fileISfile && session.display_fileISfile === item.note_file && (
													<div
														className='remove-badge mt-1 mr-1'
														style={{ cursor: 'pointer' }}
														onClick={() => onFileClick(item.note_file)}
													>
														Remove
													</div>
												)} */}
											</div>
										</div>
										<div className='box-2'>
											<div className='new-lib-title-v2'>
												<EllipsisText className='scene-title' text={item.note_file_title || ' '} length={34} />
											</div>

											<div>
												<div className='status'>
													<div
														className={
															session.display_fileISfile === item.note_file
																? 'ant-btn ant-btn-dangerous ant-btn-block'
																: 'ant-btn ant-btn-primary ant-btn-block'
														}
														style={{ cursor: 'pointer' }}
														onClick={() => onFileClick(item.note_file)}
													>
														{session.display_fileISfile === item.note_file ? 'Remove' : 'Apply'}
													</div>
													{/* {session.display_fileISfile &&
                            session.display_fileISfile === item.note_file && (
                              
                            )} */}
												</div>
											</div>

											{/* <div className='new-lib-del-v2'> */}
											<Row justify='end'>
												<Button
													danger
													type='link'
													shape='circle'
													onClick={() => deleteNote(item.note_id)}
													icon={<DeleteOutlined />}
													size='small'
													className='action-card'
												/>
											</Row>
											{/* </div> */}
										</div>
									</div>
								</>
							)
						})}
						<div className='new-library-card-v2 standard-card'>
							<div className='custom-add-button-wrap'>
								<Button type='primary' icon={<FileTextOutlined />} onClick={() => setAddFileModal(true)}>
									Add File
								</Button>
								{/* <button className='custom-add-button' onClick={() => setAddFileModal(true)}>
									<FileTextOutlined className='mr-1' /> Add File
								</button> */}
							</div>
						</div>
					</div>
				</div>

				<PrimaryModel handleCancel={() => setAddFileModal(false)} isModalVisible={addFileModal} title='Add File'>
					<EditSceneAddFileModal modalClose={() => setAddFileModal(false)} scene_id={scene_id} listNotes={listNotes} />
				</PrimaryModel>
			</>
		)
	}
	if (notesLoading) {
		return (
			<>
				<div className='text-center my-3'>
					<Spin className='mr-2' /> loading Files ...
				</div>
			</>
		)
	}

	if (notesError.length) {
		return (
			<div className='text-center my-3'>
				<Button onClick={() => listNotesFile()}>Try again</Button>
			</div>
		)
	}

	return <h4>Files</h4>
}

export default EditSceneFilesView
